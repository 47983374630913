import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@material-ui/core/Grid";
import Btverreport from "../../../Tools/Bottones/BTAgregarDocumento";
import TXTField from "../../../Tools/TextField/TextField";
import getDate from "../../../Registro/Function/obtenerFecha";
import getHours from "../../../Registro/Function/obtenerHora";
import moment from "moment";
import { formatofecha } from "../../../../api/getUmbrales";
import DialogValidaciones from "../../../Registro/Elements/Dialog/DialogValidaciones";
import { getDataToken, postDataToken } from "../../../../api/dataToken";
//idioma
import { FormattedMessage } from "react-intl";
import { LimitarTexto } from "../../../Recepcion/Elements/Functions/Validaciones";

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogAgregarOtroCarta(props) {
  var tipoCarta = props.tipoCarta;
  console.log(tipoCarta);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
    agregarOtro();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen(false);
    setvisible(false);
    props.ejecutarConsulta();
  };

  const agregarOtro = () => {
    setTitle("Agregar otro");
  };

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  ///////////////////////////////////////////////
  const [values, setValues] = React.useState({
    CAU_descripcion: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateAndSubmitForm = async (e) => {
    let idCausa;
    e.preventDefault();
    setvisible(true);

    let json = {
      CAU_descripcion: values?.CAU_descripcion,
      CAU_actualizofecha: fechaActSIN,
      CAU_actualizousuario: nombreAnalista,
    };
    console.log(json);

    try {
      const data = await postDataToken(`causas/get_listado/`, json);
      console.log(`Registro exitoso causas`, data);
      idCausa = data.CAU_idcausa;

      if (idCausa) {
        const json2 = {
          FK_Tipo_Carta: tipoCarta,
          FK_IDCausa: idCausa,
          CXC_actualizofecha: fechaActSIN,
          CXC_actualizousuario: nombreAnalista,
        };
        const data2 = await postDataToken(`cartascausa/get_listado/`, json2);
        console.log(`Registro exitoso cartaCausa`, data2);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    values.CAU_descripcion = "";
  };

  const [visible, setvisible] = React.useState(false);

  return (
    <div>
      {visible && (
        <DialogValidaciones
          title="Registro exitoso"
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description="Su registro se agrego correctamente."
          onClick={handleClose2}
        />
      )}
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <Btverreport
        onClick={handleClickOpen}
        label={
          <FormattedMessage id="dictAgreOtr" defaultMessage="Agregar otro" />
        }
      >
        {" "}
      </Btverreport>

      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        // onClose={handleClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="dialog"
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "primary" }}
        >
          <h1-dialog-title>{title}</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <form onSubmit={validateAndSubmitForm}>
          <DialogContent dividers style={{ minWidth: 500 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TXTField
                  label={tipoCarta != 3 ? "Causa" : "Documento"}
                  onChange={handleChange("CAU_descripcion")}
                  value={values?.CAU_descripcion}
                  required
                  onInput={(e) => LimitarTexto(e.target, 100)}
                />
              </Grid>
              {/*<Grid item xs={12}><BTUpload  accept=".pdf" id="inputDoc" name="inputDoc" onChange={handleFileUpload} required /></Grid>*/}
            </Grid>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              marginTop={1}
            >
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label={
                    <FormattedMessage
                      id="dictAgreOtr"
                      defaultMessage="Guardar"
                    />
                  }
                  type="submit"
                  estatus={values.CAU_descripcion.length > 0 ? false : true}
                />
              </Grid>
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label={
                    <FormattedMessage
                      id="globCance"
                      defaultMessage="Cancelar"
                    />
                  }
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
