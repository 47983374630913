export const SA = 1000000;

export const PREFIJO = "SN";

export const SUFIJO = "-2024";

export const IDESTATUSFALTINF = 10;

export const IDROLMEDICO = 7;

export const IDROLMEDICODIC = 8;

export const NOCARTARECHAZO = 1;

export const COBERPERORG = "perdida organica";

export const COBERMUENFERMEDAD = 2;

export const COBERMUEVIOLENTA = 3;

export const COBERINVALIDEZ = 6;

export const COBERINVALIDEZTO = 1;

export const COBERINVALIDEZTYP = 5;

export const PLAESTA = 1;

export const PLACOMP = 2;

export const PLAESPC = 3;

export const PLAURG = 4;

export const CINCOMILL = 5000000;

export const UNMILL = 1000000;

export const DIEZMILL = 10000000;

export const CUG = 7;

export const ENFGRV = 8;

export const EXPPI = 4;

export const COBMUJER = "cobertura mujer";

export const ENFTER = 9;

export const SNPOLIZA = "";

export const ASEG = "asegurado";

export const IDESTXPRE = 3;

export const ESTRAPRC = 1;

export const IDMODREGISTRO = 2;
export const IDMODMEDICO = 4;
export const IDMODDICTAMEN = 3;
export const IDMODINVESTIGADOR = 5;
export const IDMODCOMUNICACION = 6;
export const IDCARTARECHA = 1;
export const IDCARTARESCI = 2;
export const IDCARTASOL = 3;
export const SOLICITUDDOC = 15;
export const ESTADOCARTAWF = true;
export const IDTAREACARTADICT = 18;
export const IDCRXTCNCB = 1;

export const IDEXTGRBPO = 5;
export const IDCAPFESINI = 14;
export const IDTAVALIDACION = 15;
export const IDENVCARTA = 16;
export const IDVALCOB = 17;
export const IDCAPDASINI = 12;
export const IDGENESINI = 13;
export const IDENVMED = 14;
export const IDENVINV = 15;
export const IDENVDIC = 16;

//Módulo médico
export const IDALTOII = 41;
export const IDDICMED = 42;
export const IDDESDOC = 37;

//CONSTANTES PARA LOGIN
export const ADMINVIEW = 17;
export const ADMINEDIT = 18;
export const ADMINADD = 19;
export const CONFGENERALADD = 20;
export const CONFGENERALVIEW = 21;
export const CONFGENERALCHAN = 22;
export const CONFEMAILVIEW = 23;
export const CONFEMAILADD = 24;
export const CONFEMAILCHAN = 25;
export const ADMINISTRATCIONDEUSUARIOSVIEW = 26;
export const ADMINIESTRACIONDEUSUARIOSADD = 27;
export const ADMINISTRACIONDEUSUARIOSCHANGE = 28;

//CONSTANTES TRAMITE SINIESTRO
export const TRAM_FALLECIMIENTO = { id: 1, descripcion: "Fallecimiento" };
export const TRAM_BENEFICIOSEV = { id: 2, descripcion: "Beneficios en vida" };
export const TRAM_VENCIMIENTO = { id: 3, descripcion: "Vencimiento" };
export const TRAM_RETIROS = { id: 4, descripcion: "Retiro" };
export const TRAM_RESCATES = { id: 5, descripcion: "Rescate" };

//CONSTANTES DE PERSONAS QUE REALIZAN EL TRÁMITE

export const PERR_ASEGURADO = { id: 1, descripcion: "Asegurado" };
export const PERR_CONTRATANTE = { id: 2, descripcion: "Contratante" };
export const PERR_BENEFICIARIO = { id: 3, descripcion: "Beneficiario" };
export const PERR_AGENTE = { id: 4, descripcion: "Agente" };
export const PERR_OTRO = { id: 5, descripcion: "Otro" };
export const PERR_INTERNO = { id: 6, descripcion: "Usuario Interno" };

export const CTAREC = "Carta Rechazo";

export const CAUSAREC = "No cuenta con el beneficio";

export const IDCOMESPC = 3;

export const INVSQL = "INV";

export const EXP = "EXP";

export const ROLAGEN = "agente";

export const CARTA = "Carta_";

export const TAM_ARCHIVOS_MB = 20;

export const TIPOENVIODICT = 1;

export const TIPOENTRECOM = 2;

//Constante para la moneda Nacional
export const MONEDA_NACIONAL = {
  id: 1,
  nombre: "Peso Mexicano",
  abreviatura: "MXN",
};

//Constantes para catalogo conceptos Reserva Ajuste
export const TOTAL_CONTRA_ACC_RESERVA = {
  id: 11,
  description: "Total a descontar",
};
export const TOTAL_FAVOR_ACC_RESERVA = { id: 10, description: "Total a favor" };
export const TOTAL_NETO_ACC_RESERVA = { id: 12, description: "Total neto" };

//CONSTANTE COBERTURA FALLECIMIENTO POR DEFECTO
export const COBERTURA_FALLE_DEFAULT = {
  id: 17,
  description: "Cobertura por fallecimiento",
  obligatoria: true,
};
export const COBERTURA_VENC_DEFAULT = {
  id: 16,
  description: "",
  obligatoria: true,
};
export const COBERTURA_RETIRO_DEFAULT = {
  id: 15,
  description: "Retiro",
  obligatoria: true,
};
export const COBERTURA_RESCA_DEFAULT = {
  id: 14,
  description: "Rescate",
  obligatoria: true,
};

//CONSTANTES COBERTURAS BASICAS
export const COBERTURAS_BASICAS = [
  {
    id: 17,
    description: "Cobertura por fallecimiento",
    tipoTramite: TRAM_FALLECIMIENTO.id,
  },
];
//CONSTANTES POR TIPO TRAMITE EN RECEPCION, TRAMITE MANUAL O PM
export const TRAM_MANUAL = 1;
export const TRAM_PM = 2;

//CONSTANTES TIPO PERSONA

export const PER_FISICA = 1;

//Constante para la MOVIMIENTO CONTABLE
export const MOV_RESERVA_OPC = {
  id: 1,
  descripcion: "Movimiento a la reserva OPC",
};
export const PAGOS_PRIMAS_EXENCION = {
  id: 2,
  descripcion: "Pagos de primas por exención",
};
export const RESERVAS_ACTUARIALES = {
  id: 3,
  descripcion: "Reservas actuariales",
};
export const CANCELACION_RESERVA_PRESCRIPCION = {
  id: 4,
  descripcion: "Cancelación de la reserva por prescripsión",
};
export const CONSTITUCION_INTERESES_MORATORIOS = {
  id: 5,
  descripcion: "Constitución de intereses moratorios",
};
export const PAGO = { id: 6, descripcion: "Pago" };
export const REASEGURO = { id: 7, descripcion: "Reaseguro" };
export const SINIESTROS_CNSF = {
  id: 8,
  descripcion: "Siniestros ordenados por la CNSF",
};
export const ADEUDADOS_SUMA_ASEGURADA_FALLECIMIENTO = {
  id: 9,
  descripcion: "Adeudados a descontar de la suma asegurada por fallecimiento",
};
export const VALORES_SUMA_ASEGURADA_FALLECIMIENTO = {
  id: 10,
  descripcion: "Valores a adicionar a la suma asegurada por fallecimiento",
};
export const TRASLADO_SUMA_ASEGURADA_FALLECIMIENTO_A_CUENTAS = {
  id: 11,
  descripcion:
    "Traslado de la suma asegurada por fallecimiento a cuentas de administración de pensiones o fideicomisos",
};
export const TRASLADO_SUMA_ASEGURADA_POLIZAS = {
  id: 12,
  descripcion:
    "Traslado de la suma asegurada de pólizas vencidas a cuentas de administración de pensiones o fideicomisos",
};
export const HONORARIOS = {
  id: 13,
  descripcion: "Honorarios por investigación",
};

//Constanstes estatus pagos
export const ESTATUS_PAGOS = {
  PAGADO: { id: 1, descripcion: "PAGADO" },
  CANCELADO: { id: 2, descripcion: "CANCELADO" },
};

//Constanstes tipo finiquito
export const TIPO_FINIQUITO = {
  FINIQUITO_GENERAL: { id: 1 },
  FINIQUITO_INDIVIDUAL: { id: 2 },
};

//Constantes estatus gestion
export const ESTATUS_GESTION_COMP = {
  APROBADO_ID: 1,
  DENEGADO_ID: 2,
  PENDIENTE_ID: 3,
  FINALIZADO_ID: 4,
};

//Constantes tipo movimiento gestion competencias Reserva / Pago
export const ESTATUS_GCRP = [
  { GC_movreserva: { value: true, descripcion: "RESERVA" } },
  { GC_movpago: { value: true, descripcion: "PAGO" } },
];

//Constantes tarifa investigación
export const TARIFA_INV = {
  SIMPLE: 1,
  COMPLEJA: 2,
  ESPECIAL: 3,
  URGENTE: 4,
};

//Constantes comunicacion investigador - dictamen
export const APARTADO = {
  INVESTIGADOR: 1,
  DICTAMEN: 2,
};

export const IDPAISMEXICO = 2;
