import React from "react";
import { useState, useEffect } from "react";
import {
  getDataToken,
  postDataToken,
  putDataToken,
} from "../../../api/dataToken";
import {
  formatofecha,
  diasestandarizados,
  diasespecial,
  diascomplejo,
  diascug,
  diasMaxTramite,
} from "../../../api/getUmbrales";
import { Select1 } from "../../Tools/Selectss/Select1";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import TXTField from "../../Tools/TextField/TextField";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import Check2 from "../../Tools/CheckBox/Check2";
import DialogCascadeTwoButtons from "./Dialog/DialogCascadeTwoButtons";
import DialogInvestigador from "./Dialog/Dialog";
import DialogInv from "./Dialog/DialogInvestigador";
import DialogAviso from "./Dialog/DialogAviso";
import moment from "moment";
import getDate from "../Function/obtenerFecha";
import getHours from "../Function/obtenerHora";
import SumaAsegurada from "../Function/sumaAsegurada";
import obtenerPoliza from "../Function/obtenerPoliza";
import obtenerDias from "../Function/obtenerDias";
import {
  LimitarTextoSinCondiciones,
  LimitarTextoCodigo,
} from "../Function/limitarTexto";

//Idioma
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  PREFIJO,
  SUFIJO,
  COBERMUENFERMEDAD,
  COBERMUEVIOLENTA,
  IDROLMEDICO,
  CINCOMILL,
  PLAESTA,
  PLACOMP,
  PLAESPC,
  UNMILL,
  DIEZMILL,
  CUG,
  PLAURG,
  ENFGRV,
  ENFTER,
  IDESTATUSFALTINF,
  IDCOMESPC,
  COBMUJER,
  ESTRAPRC,
  IDMODREGISTRO,
  IDCAPDASINI,
  IDGENESINI,
  IDENVMED,
  IDENVINV,
  IDENVDIC,
  TARIFA_INV,
  IDPAISMEXICO,
} from "../../../utils/constanstoken";
import {
  LimitarDigitos,
  LimitarTexto,
} from "../../Recepcion/Elements/Functions/Validaciones";
import GetTareas from "../../../api/getTareas";

export default function CompDefuncion(props) {
  // Traemos los datos que nos estan pasando de la pantalla anterior
  const location = useLocation();
  const idRow = location.state;
  console.log(idRow);
  const [FolioRec, setFolioRec] = useState(idRow?.RECT_idfolio?.RECT_idfolio);
  //Estados para guardar el id con el rol médico certificador
  const [rolPersona, setRolpersona] = useState(IDROLMEDICO);
  console.log("Id rol persona " + rolPersona);

  //Obtenemos las tareas para nuestro módulo
  const objetosCoincidentes = GetTareas(IDMODREGISTRO);
  console.log(objetosCoincidentes);

  //Estado para guardar los datos.
  const [IdPersonaApi, setIdPersonaApi] = useState(
    idRow?.PER_idpersona?.PER_idpersona
  );
  const [Datos, setDatos] = React.useState(idRow);
  console.log(Datos);
  console.log(IdPersonaApi);

  //Estado para guardar los datos.
  const [DatosComunicacion, setDatosComunicacion] = React.useState([]);
  //Guardamos el id de las pólizas
  const [IDPoliza, setIDPoliza] = useState([]);
  const [polizastra, setpolizastra] = useState([]);
  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");

  //Obtener datos del asegurado y su id
  const getData = async () => {
    try {
      var result = await getDataToken(
        `charolacomunicacion/get_idFolio/${FolioRec}/`
      );
      setDatosComunicacion(result);
      var resultPolizas = await getDataToken(
        `polizaportramites/get_folio/${FolioRec}/`
      );
      setpolizastra(resultPolizas);
      setIDPoliza(
        resultPolizas.map((persona) => persona.POL_idpoliza?.POL_idpoliza)
      );
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  console.log(DatosComunicacion);
  console.log(nombreAnalista);
  console.log(polizastra);

  //Validamos si existe

  var contenidoPolizas = "";
  for (var key in polizastra) {
    if (polizastra[key]?.POL_idpoliza?.POL_numpoliza) {
      contenidoPolizas += polizastra[key].POL_idpoliza.POL_numpoliza + "\n"; // Agregar un salto de línea
    }
  }

  //Obtenemos la fecha de recepción del trámite
  const fechaRecepcion = moment(Datos.RECT_idfolio?.RECT_fecharecepcion).format(
    "YYYY-MM-DD"
  );
  console.log("Fecha Recepcion: " + fechaRecepcion);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();
  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;
  console.log("fechaActSIN" + fechaActSIN);

  //Validación solo acepta números
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (/[^0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  //validación para que acepte solo letras.
  function handleKeyPressL(event) {
    const regex = /^[a-zA-Z]*$/;
    const isValidInput = regex.test(event.key);
    if (!isValidInput) {
      event.preventDefault();
    }
  }

  //Estados utilizados para sepomex
  const [SEP_Id, setSEP_Id] = useState("");
  const [SEP_asenta, setSEP_asenta] = useState("");
  const [SEP_municipio, setSEP_municipio] = useState("");
  const [SEP_estado, setSEP_estado] = useState("");
  const [SEP_ciudad, setSEP_ciudad] = useState("");
  const [SEPT_Id, setSEPT_Id] = useState("");
  const [SEPT_asenta, setSEPT_asenta] = useState("");
  const [SEPT_municipio, setSEPT_municipio] = useState("");
  const [SEPT_estado, setSEPT_estado] = useState("");
  const [SEPT_ciudad, setSEPT_ciudad] = useState("");
  const [codpostal, setCodPostal] = useState("");
  const [codigopostal, setCodigoPostal] = useState("");
  const [colonias, setColonias] = useState([]);
  const [colonia, setColonia] = useState([]);
  const [dataSelectColonias, setDataSelectColonias] = useState([]);
  const [dataSelectColonia, setDataSelectColonia] = useState([]);

  //Traer los datos de CP para los dos formatos de cp
  const getCP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonia(result);
      if (result <= 0) {
        setDataSelectColonia([]);
        setSEPT_estado("");
        setSEPT_municipio("");
        setSEPT_asenta("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonias(result);
      if (result <= 0) {
        setDataSelectColonias([]);
        setSEP_estado("");
        setSEP_municipio("");
        setSEP_asenta("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //Primer formulario CP

  const handleSubmitone = () => {
    if (codigopostal === "") {
      document.getElementById("txtCP").focus();
    } else {
      getCP(codigopostal);
    }
  };

  const getInfoasentamiento = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonia.forEach((element) => {
      if (dataColonia === element.id) {
        setSEPT_Id(element.id);
        setSEPT_municipio(element.SEP_municipio);
        setSEPT_estado(element.SEP_estado);
        setSEPT_ciudad(element.SEP_ciudad);
        setSEPT_asenta(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    if (colonia.length > 0) {
      let dataColonia = colonia;
      dataColonia.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonia(dataColonia);
    }
  }, [colonia]);

  console.log("Id sepomex primero" + SEPT_Id);

  //Segundo formulario CP

  const handleSubmit = () => {
    if (codpostal === "") {
      document.getElementById("txtCP2").focus();
    } else {
      getCodP(codpostal);
    }
  };

  const getInfoasenta = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEP_Id(element.id);
        setSEP_municipio(element.SEP_municipio);
        setSEP_estado(element.SEP_estado);
        setSEP_ciudad(element.SEP_ciudad);
        setSEP_asenta(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    if (colonias.length > 0) {
      let dataColonias = colonias;
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias);
    }
  }, [colonias]);

  console.log("Id sepomex " + SEP_Id);

  //Estados para conocer el valor de los switch
  const [isChecked, setIsChecked] = useState(false);
  const [isOn, setIsOn] = useState(false);

  // Valor del check 1
  const handleSwitchChange = (event) => {
    setIsOn(event.target.checked);
  };

  console.log("checked 1" + isOn);

  // Valor del check 2
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  console.log("checked 2" + isChecked);

  let personasFiltradas;

  //Estados para guardar datos del investigador
  const [IdPersonaInv, setIdPersonaInv] = useState("");
  const [NombreInv, setNombreInv] = useState("");

  //Buscar un Investigador al azar
  const getInvestigador = async () => {
    try {
      var result = await getDataToken(`login/get_listado/`);
      personasFiltradas = result.filter(
        (persona) => persona.SNYTLOG_investigacion === true
      );
      personasFiltradas.forEach((persona) => {
        console.log(persona.id);
      });

      // Elegir una persona aleatoria
      const indiceAleatorio = Math.floor(
        Math.random() * personasFiltradas.length
      );
      const personaAleatoria = personasFiltradas[indiceAleatorio];
      setIdPersonaInv(personaAleatoria.id);
      setNombreInv(
        personaAleatoria.SNYTLOG_username +
          " " +
          personaAleatoria.SNYTLOG_surnamedad +
          " " +
          personaAleatoria.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getInvestigador();
  }, []);

  console.log("Investigador" + IdPersonaInv);

  //Estados para guardar datos del dictaminador
  const [IdPersonaDic, setIdPersonaDic] = useState("");
  const [NomDic, setNomDic] = useState("");

  //Buscar el dictaminador al azar
  const getDictaminador = async () => {
    try {
      var result = await getDataToken(`login/get_listado/`);
      personasFiltradas = result.filter(
        (persona) => persona.SNYTLOG_dictamen === true
      );
      personasFiltradas.forEach((persona) => {
        console.log(persona.id);
      });

      // Elegir una persona aleatoria
      const indiceAleatorio = Math.floor(
        Math.random() * personasFiltradas.length
      );
      const personaAleatoria = personasFiltradas[indiceAleatorio];
      setIdPersonaDic(personaAleatoria.id);
      setNomDic(
        personaAleatoria.SNYTLOG_username +
          " " +
          personaAleatoria.SNYTLOG_surnamedad +
          " " +
          personaAleatoria.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getDictaminador();
  }, []);

  console.log("Dictaminador" + IdPersonaDic);

  //Estados para guardar datos del médico
  const [IdPersonaMedico, setIdPersonaMedico] = useState("");
  const [NomMedic, setNomMedic] = useState("");

  //Buscar el médico al azar
  const getMedico = async () => {
    try {
      var result = await getDataToken(`login/get_listado/`);
      personasFiltradas = result.filter(
        (persona) => persona.SNYTLOG_medico === true
      );
      personasFiltradas.forEach((persona) => {
        console.log(persona.id);
      });

      // Elegir una persona aleatoria
      const indiceAleatorio = Math.floor(
        Math.random() * personasFiltradas.length
      );
      const personaAleatoria = personasFiltradas[indiceAleatorio];
      setIdPersonaMedico(personaAleatoria.id);
      setNomMedic(
        personaAleatoria.SNYTLOG_username +
          " " +
          personaAleatoria.SNYTLOG_surnamedad +
          " " +
          personaAleatoria.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getMedico();
  }, []);

  console.log("Medico" + IdPersonaMedico);

  //Estados para guardar los datos de clasificación
  const [clasificacion, setClasificacion] = useState([]);
  const [idclasificacion, setIdclasificacion] = useState("");
  //Estados para guardar los datos de los paises
  const [paises, setpaises] = useState([]);

  // Traemos los datos de clasificación para llenar el combo
  const getClasificacion = async () => {
    try {
      var result = await getDataToken(`complejidadsiniestros/get_listado/`);
      let array = result.map((element) => {
        return {
          ...element,
          identificador: element.SINC_idcomplejidadsiniestro,
          texto: element.SINC_complejidad,
        };
      });
      setClasificacion(array);
      var resultPais = await getDataToken(`paises/get_listado/`);
      let arrayPais = resultPais.map((element) => {
        return {
          ...element,
          identificador: element.PAI_idpais,
          texto: element.PAI_pais,
        };
      });
      setpaises(arrayPais);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getClasificacion();
  }, []);

  //Estado donde se guardara el dato
  const [pais, setpais] = useState("");
  const [idpais, setidpais] = useState("");
  const handleChangePais = (e) => {
    const dataPais = parseInt(e.target.value);
    paises.forEach((element) => {
      if (dataPais === element.PAI_idpais) {
        setidpais(element.PAI_idpais);
        setpais(element.PAI_pais.toLowerCase());
      }
    });
  };

  console.log("Id pais " + idpais);
  console.log("Pais" + pais);

  //Estado donde se guardara el dato
  const [plataforma, setPlataforma] = useState("");

  const handleChange = (e) => {
    const dataClasificacion = parseInt(e.target.value);
    clasificacion.forEach((element) => {
      if (dataClasificacion === element.SINC_idcomplejidadsiniestro) {
        setIdclasificacion(element.SINC_idcomplejidadsiniestro);
        setPlataforma(element.SINC_complejidad.toLowerCase());
      }
    });
  };

  console.log("Id clasificación " + idclasificacion);
  console.log("Plataforma " + plataforma);

  //Guarda todo los datos del arreglo
  const [tipificacionSiniestro, settipificacionSiniestro] = useState([]);
  //Guardar el id y datos del siniestro
  const [IdSiniestroREC, setIdSiniestroREC] = useState([]);
  const [NoSiniestroAPI, setNoSiniestroAPI] = useState([]);
  const [fechaSin, setfechaSin] = useState([]);
  const [IdSiniestro, setIdSiniestro] = useState("");

  //Obtenemos el ultimo folio para la función de generar folio

  const getUltimoFolio = async () => {
    try {
      var result = await getDataToken(`siniestros/get_listado/`);
      const siniestrosEncontrados = result.filter(
        (persona) => persona.RECT_idfolio?.RECT_idfolio === FolioRec
      );
      settipificacionSiniestro(siniestrosEncontrados);
      setIdSiniestroREC(
        siniestrosEncontrados.map((persona) => persona.SIN_idsiniestro)
      );
      setNoSiniestroAPI(
        siniestrosEncontrados.map((persona) => persona.SIN_numerosiniestro)
      );
      setfechaSin(
        siniestrosEncontrados.map((persona) => persona.SIN_actualizofecha)
      );
      const ultimoRegistro = Math.max(
        ...result.map((registro) => registro.SIN_idsiniestro)
      );
      console.log("Resultado de último Siniestros=>", ultimoRegistro);
      setIdSiniestro(ultimoRegistro + 1);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUltimoFolio();
  }, []);

  if (IdSiniestroREC) {
    console.log("ID del siniestro" + IdSiniestroREC);
    console.log("nO.Siniestro del siniestro" + NoSiniestroAPI);
    console.log("Fecha del siniestro" + fechaSin);
    console.log(moment(fechaSin[0]).format(formatofecha));
    console.log(tipificacionSiniestro);
    console.log(
      tipificacionSiniestro[0]?.CSIN_idclasificacionsiniestros?.CSIN_clasificacionsiniestros?.toLowerCase()
    );
  } else {
    console.log("No se encontró ningún registro que coincida con la búsqueda.");
  }

  //Generar un folio al azar
  let ejecutado = false;

  function generarFolio(IdSiniestrorecepcion) {
    const numero = Math.floor(Math.random() * 1000000);
    const numeroFolio = ("000000" + numero).slice(-6);
    return PREFIJO + numeroFolio + IdSiniestrorecepcion + IdSiniestro + SUFIJO;
  }

  const [NoSiniestro, setNoSiniestro] = useState({}); // Declarar NoSiniestro como estado

  useEffect(() => {
    if (!ejecutado) {
      const nuevosSiniestros = {};
      for (let i = 0; i < IdSiniestroREC.length; i++) {
        const folio = generarFolio(IdSiniestroREC[i]);
        nuevosSiniestros[i] = folio;
      }
      setNoSiniestro(nuevosSiniestros); // Actualizar el estado usando setNoSiniestro
      ejecutado = true;
    }
  }, [IdSiniestroREC, ejecutado]);

  // Ejemplo de uso
  console.log("Número de siniestro", NoSiniestro); // No uses el "+" para concatenar strings, utiliza una coma ","

  //Variables que se ocupan para poder almacenar el valor seleccionado

  const [Title, setTitle] = useState("");
  const [description1, setdescription1] = useState("");
  const [description2, setdescription2] = useState("");
  const [description3, setdescription3] = useState("");
  const [description4, setdescription4] = useState("");
  const [description5, setdescription5] = useState("");
  const [description6, setdescription6] = useState("");
  const [description7, setdescription7] = useState("");
  const [description8, setdescription8] = useState("");
  const [postMedico, setpostmedico] = useState(false);
  const [visibleInv, setVisibleInv] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [visibleboton, setvisibleboton] = useState("");
  const [reclamacion, setreclamacion] = useState("");
  const [calleDefuncion, setcalleDefuncion] = useState("");
  const [edadContratacion, setedadContratacion] = useState("");
  const [edadSiniestro, setedadSiniestro] = useState("");
  const [nombreMedico, setnombreMedico] = useState("");
  const [apellidoPaterno, setapellidoPaterno] = useState("");
  const [apellidoMaterno, setapellidoMaterno] = useState("");
  const [cedula, setcedula] = useState("");
  const [calleMedico, setcalleMedico] = useState("");
  const [numExt, setnumExt] = useState("");
  const [numInt, setnumInt] = useState("");
  const [ciudadDefuncion, setciudadDefuncion] = useState("");
  const [estadoDefuncion, setestadoDefuncion] = useState("");
  const [codigoDefuncion, setcodigoDefuncion] = useState("");
  const [ciudadMedico, setciudadMedico] = useState("");
  const [estadoMedico, setestadoMedico] = useState("");
  const [codigoMedico, setcodigoMedico] = useState("");

  const estiloDiv = {
    marginTop: "-18px", // Aplica un margen de 20px alrededor del div
  };

  //Estado donde se guardara el resultado
  const [coberturasSA, setCoberturasSA] = useState([]);

  //Obtener todas las coberturas
  const getCPXT = async () => {
    try {
      var result = await getDataToken(
        `coberturaspolizasportramite/get_folio/${FolioRec}/`
      );
      setCoberturasSA(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  React.useEffect(() => {
    getCPXT();
  }, []);

  console.log(coberturasSA);

  //Validar cobertura mujer
  const coberturaMujer = coberturasSA.some(
    (item) => item.COB_idcobertura.COB_cobertura.toLowerCase() === COBMUJER
  );

  console.log(coberturaMujer);

  // Arreglo para almacenar las fechas de emisión
  const fechaEmision = [];

  // Objeto auxiliar para realizar la verificación
  const polizasRegistradas = {};

  // Iterar sobre cada registro
  coberturasSA.forEach((registro) => {
    const idPoliza = registro?.POL_idpoliza?.POL_idpoliza;
    const fechasEmision = registro?.POL_idpoliza?.POL_polizafecemisión;

    // Verificar si ya se ha registrado una fecha para la póliza actual
    if (!polizasRegistradas[idPoliza]) {
      // Guardar la fecha de emisión en el arreglo
      const polizaData = {
        idPoliza: idPoliza,
        fechaEmision: fechasEmision,
      };
      fechaEmision.push(polizaData);

      // Marcar la póliza como registrada en el objeto auxiliar
      polizasRegistradas[idPoliza] = true;
    }
  });

  // Imprimir las fechas de emisión en la consola
  console.log(fechaEmision);

  // Variable para almacenar la suma de CPT_sumaasegurada
  var total = coberturasSA.reduce(function (acc, poliza) {
    var idPoliza = poliza?.POL_idpoliza?.POL_idpoliza;

    if (!acc[idPoliza]) {
      acc[idPoliza] = 0;
    }

    acc[idPoliza] += poliza.CPT_sumaasegurada;
    return acc;
  }, {});

  console.log(total);

  // Validar SA
  // Extraer los valores de cantidad del objeto total
  const cantidades = Object.values(total);
  console.log(cantidades);

  // Pasar las cantidades como argumento a la función SumaAsegurada
  let resultadoSA;
  cantidades.forEach(function (cantidad, index) {
    resultadoSA = SumaAsegurada(cantidad);

    console.log("Resultado para cantidad", index + 1, ":", resultadoSA);
  });

  // Validar póliza disputable
  const resultadoPoliza = {};
  fechaEmision.forEach((emision, index) => {
    // Acceder directamente a las propiedades idPoliza y fechaEmision de la póliza
    const { idPoliza, fechaEmision } = emision;
    let resultadoFinal = obtenerPoliza(fechaEmision, fechaRecepcion);
    const objResultado = {
      estatusdisputable: resultadoFinal,
      idPoliza: idPoliza,
    };
    resultadoPoliza[index] = objResultado;
  });

  console.log("Resultado póliza", resultadoPoliza);

  // Para las validaciones juntaremos los resultados en un mismo arreglo
  const combinacionArr1 = tipificacionSiniestro.map((item, index) => {
    // Combinar el elemento del arreglo tipificacionSiniestro con el elemento del arreglo resultadoPoliza en la misma posición
    const combinedItem = {
      ...item,
      ...resultadoPoliza[index],
    };

    return combinedItem;
  });

  console.log(combinacionArr1);

  //Arreglo 2 para juntar los datos

  const combinacionArr2 = combinacionArr1.map((obj) => {
    const { idPoliza } = obj;
    return {
      ...obj,
      ["total"]: total[idPoliza],
    };
  });

  console.log(combinacionArr2);

  // Función para combinar los arreglos por POL_idpoliza
  function combinarArreglosPorIdPoliza(arreglo1, arreglo2) {
    // Creamos un objeto para mapear los elementos del arreglo1 por POL_idpoliza
    const mapaArreglo1 = arreglo1.reduce((mapa, elemento) => {
      mapa[elemento.POL_idpoliza.POL_idpoliza] = elemento;
      return mapa;
    }, {});

    // Combinamos los arreglos por POL_idpoliza
    const resultado = arreglo2.map((elemento) => {
      const idPoliza = elemento.idPoliza;
      const elementoArreglo1 = mapaArreglo1[idPoliza];
      return {
        ...elementoArreglo1,
        ...elemento,
      };
    });

    return resultado;
  }

  // Llamamos a la función para combinar los arreglos
  const arrCombinado = combinarArreglosPorIdPoliza(polizastra, combinacionArr2);

  // Mostramos el resultado combinado
  console.log(arrCombinado);

  const arreglo1Convertido = Object.values(NoSiniestro);

  const resultadoCombinado = arreglo1Convertido.map((elemento, index) => {
    return {
      ...arrCombinado[index], // Combina los elementos del arreglo2 usando la posición del arreglo1
      ["NumSinigenerado"]: elemento, // Agrega el elemento del arreglo1 usando el índice como clave
    };
  });

  console.log(resultadoCombinado);

  // Comprobamos si alguno de los objetos tiene la propiedad TEST_estatustramite con el valor "falta informacion"
  const existeFaltaInformacion = coberturasSA?.some(
    (objeto) =>
      objeto?.RECT_idfolio?.TEST_idestatustramite?.TEST_idestatustramite ===
      IDESTATUSFALTINF
  );

  // 'existeFaltaInformacion' será true si algún objeto cumple la condición, y false si ninguno la cumple.
  console.log("Existe falta de información:", existeFaltaInformacion);

  let bandera = false;

  if (!bandera && existeFaltaInformacion) {
    resultadoCombinado.forEach((siniestros) => {
      let siniestro = {
        SINC_idcomplejidadsiniestro: IDCOMESPC,
        RECT_idfolio: FolioRec,
        SIN_actualizofecha: fechaActSIN,
        SIN_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `siniestros/get_id/${siniestros.SIN_idsiniestro}/`,
        siniestro
      ).then((data) => {
        console.log(`Registro ${siniestros.SIN_idsiniestro} actualizado`);
      });
    });
    bandera = true;
  }

  let dias, diasMax;

  //Asignar los tiempos de respuesta
  function obtenerTiempos(plataforma) {
    console.log("entro x2");
    dias = diasMaxTramite;
    resultadoCombinado.map((siniestro) => {
      if (
        siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
        siniestro?.estatusdisputable === true &&
        plataforma === PLAESTA &&
        siniestro?.total < UNMILL
      ) {
        diasMax = diasestandarizados;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion1");
      } else if (
        siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
        siniestro?.estatusdisputable === true &&
        plataforma === PLACOMP &&
        siniestro?.total > UNMILL &&
        siniestro?.total < DIEZMILL
      ) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion2");
      } else if (
        siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
        siniestro?.estatusdisputable === true &&
        plataforma === PLAESPC &&
        siniestro?.total > DIEZMILL
      ) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion3");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUEVIOLENTA &&
          siniestro?.total < UNMILL &&
          plataforma === PLAESPC) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUEVIOLENTA &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL &&
          plataforma === PLAESPC) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUEVIOLENTA &&
          siniestro?.total > DIEZMILL &&
          plataforma === PLAESPC)
      ) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion4");
      } else if (
        (siniestro?.estatusdisputable === false &&
          plataforma === PLAESPC &&
          siniestro?.total < UNMILL &&
          siniestro?.CSIN_idclasificacionsiniestros
            ?.CSIN_idclasificacionsiniestros !== null) ||
        (siniestro?.estatusdisputable === false &&
          plataforma === PLAESPC &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL &&
          siniestro?.CSIN_idclasificacionsiniestros
            ?.CSIN_idclasificacionsiniestros !== null) ||
        (siniestro?.estatusdisputable === false &&
          plataforma === PLAESPC &&
          siniestro?.total > DIEZMILL &&
          siniestro?.CSIN_idclasificacionsiniestros
            ?.CSIN_idclasificacionsiniestros !== null)
      ) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion5");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === CUG &&
          siniestro?.estatusdisputable === true &&
          siniestro?.total < UNMILL &&
          plataforma === PLAURG) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === CUG &&
          siniestro?.estatusdisputable === true &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL &&
          plataforma === PLAURG) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === CUG &&
          siniestro?.estatusdisputable === true &&
          siniestro?.total > DIEZMILL &&
          plataforma === PLAURG) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === CUG &&
          siniestro?.POL_idpoliza?.POL_polizarehabilitada !== null &&
          siniestro?.total < UNMILL &&
          plataforma === PLAURG) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === CUG &&
          siniestro?.POL_idpoliza?.POL_polizarehabilitada !== null &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL &&
          plataforma === PLAURG) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === CUG &&
          siniestro?.POL_idpoliza?.POL_polizarehabilitada !== null &&
          siniestro?.total > DIEZMILL &&
          plataforma === PLAURG)
      ) {
        diasMax = diascug;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion6");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.POL_idpoliza?.POL_polizarehabilitada !== null &&
          siniestro?.total < UNMILL &&
          plataforma === PLAESTA) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.estatusdisputable === true &&
          siniestro?.total < UNMILL &&
          plataforma === PLAESTA)
      ) {
        diasMax = diasestandarizados;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion7");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.POL_idpoliza?.POL_polizarehabilitada !== null &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL &&
          plataforma === PLACOMP) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.estatusdisputable === true &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL &&
          plataforma === PLACOMP)
      ) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion8");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.POL_idpoliza?.POL_polizarehabilitada !== null &&
          siniestro?.total > DIEZMILL &&
          plataforma === PLAESPC) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.estatusdisputable === true &&
          siniestro?.total > DIEZMILL &&
          plataforma === PLAESPC)
      ) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion9");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.POL_idpoliza?.POL_polizarehabilitada !== null &&
          siniestro?.total > DIEZMILL &&
          plataforma === PLAESPC) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.estatusdisputable === false &&
          siniestro?.total > DIEZMILL &&
          plataforma === PLAESPC)
      ) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion10");
      } else if (
        siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUEVIOLENTA &&
        plataforma === PLAESPC
      ) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion11");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === ENFGRV &&
          plataforma === PLACOMP &&
          siniestro?.total < UNMILL) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === ENFGRV &&
          plataforma === PLACOMP &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === ENFGRV &&
          plataforma === PLACOMP &&
          siniestro?.total > DIEZMILL)
      ) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion12");
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === ENFTER &&
          plataforma === PLACOMP &&
          siniestro?.total < UNMILL) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === ENFTER &&
          plataforma === PLACOMP &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === ENFTER &&
          plataforma === PLACOMP &&
          siniestro?.total > DIEZMILL)
      ) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion13");
      } else if (plataforma === PLAESTA) {
        diasMax = diasestandarizados;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion14");
      } else if (plataforma === PLACOMP) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion15");
      } else if (plataforma === PLAESPC) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion16");
      } else if (coberturaMujer && plataforma === PLACOMP) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion17");
      }
    });

    console.log("dias" + dias);
  }

  if (plataforma.length > 0) {
    obtenerTiempos(idclasificacion);
    console.log("entro");
  }

  //Variable para ver si se mandan todos los registro antes del put
  let data = [],
    itemData;

  console.log(data);

  let dataSiniestros = [],
    siniestro;

  console.log(dataSiniestros);

  const [visibleContar, setvisibleContar] = useState(false);
  const [count, setcount] = useState(0);

  const getMedicos = async (valor) => {
    try {
      var result = await getDataToken(`personas/get_listado_cedula/${valor}`);
      console.log("Resultado personas =>", result);
      // Contar cuántos resultados se obtuvieron
      setcount(result.length);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSubmitcontar = () => {
    if (cedula === "") {
      document.getElementById("cedula").focus();
    } else {
      // getMedicos(23454256);
      getMedicos(cedula);
      console.log(count);
      console.log(visibleContar);
      setvisibleContar(true);
    }
  };
  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setvisibleContar(false);
  };

  async function putCharolaRegistro(aux) {
    try {
      var result = await putDataToken(
        `charolaregistro/get_id/${Datos.CHREG_idcharolareg}/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async function postCharolaMedico(aux) {
    try {
      var result = await postDataToken(`charolamedico/get_listado/`, aux);
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async function postFoliosiniestro(aux) {
    try {
      var result = await postDataToken(
        `polizasfolioxsiniestro/get_listado/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //Estado donde se guardara el resultado
  const [estatusTramite, setestatusTramite] = useState([]);

  //Obtener todos los estatus del trámite
  const getestatusTramite = async () => {
    try {
      var result = await getDataToken(`estatustramite/get_id/${ESTRAPRC}/`);
      setestatusTramite(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  React.useEffect(() => {
    getestatusTramite();
  }, []);

  console.log(estatusTramite?.TEST_idestatustramite);

  async function putRecepcion(aux) {
    try {
      var result = await putDataToken(`recepcion/get_id/${FolioRec}/`, aux);
      console.log("Actualización exitosa", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async function postInvestigador(aux) {
    try {
      var result = await postDataToken(`investigacion/get_listado/`, aux);
      console.log("Registro exitoso", result);
      const IDPolizas = result.POL_idpoliza;
      const IDInvestigador = result.INV_idinvestigacion;
      console.log(IDPolizas);
      console.log(IDInvestigador);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const [visibleNoTurnInv, setvisibleNoTurnInv] = useState(false);

  const handleClickMedico = () => {
    resultadoCombinado.map((siniestro) => {
      if (
        siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
        siniestro?.estatusdisputable === false &&
        siniestro?.total < CINCOMILL
      ) {
        console.log("entro condicion 1");
        setVisibleInv(true);
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.estatusdisputable === false &&
          siniestro?.total > CINCOMILL) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUENFERMEDAD &&
          siniestro?.estatusdisputable === true &&
          siniestro?.total > CINCOMILL)
      ) {
        console.log("entro condicion 2");
        setVisibleInv(true);
      } else if (
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUEVIOLENTA &&
          siniestro?.estatusdisputable === false) ||
        (siniestro?.CSIN_idclasificacionsiniestros
          ?.CSIN_idclasificacionsiniestros === COBERMUEVIOLENTA &&
          siniestro?.estatusdisputable === true)
      ) {
        console.log("entro condicion 3");
        setVisibleInv(true);
      } else if (resultadoSA) {
        console.log("entro condicion 5");
        setVisibleInv(true);
      } else {
        setvisibleNoTurnInv(true);
      }
    });
  };

  console.log("nuevo folio" + FolioRec);

  const handleClickInvestigador = () => {
    if (
      fechaEmpAn != "" &&
      IdPersonaInv != "" &&
      FolioRec != "" &&
      IDPoliza.length > 0
    ) {
      const indice = objetosCoincidentes.findIndex(
        (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDENVINV
      );
      //Actualizamos a true
      objetosCoincidentes[indice].estado = true;
      // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
      const registrosConEstado = objetosCoincidentes.filter(
        (registro) => registro.estado
      );
      console.log(registrosConEstado);

      let bandera = false;

      for (const itempro of resultadoCombinado) {
        for (const itemTra of registrosConEstado) {
          for (const siniestro of tipificacionSiniestro) {
            let registro = {
              RECT_idfolio: FolioRec,
              POL_idpoliza: itempro?.POL_idpoliza?.POL_idpoliza,
              WTM_idwftareasxmodulos: itemTra.WTM_idwftareasxmodulos,
              PER_idpersona: IdPersonaApi,
              SIN_idsiniestro: siniestro?.SIN_idsiniestro,
              WKT_fechawktramite: fechaActSIN,
              WKT_seejecuto: itemTra.estado,
              WKT_actualizofecha: fechaActSIN,
              WKT_actualizousuario: nombreAnalista,
            };
            postDataToken(`wktramite/get_listado/`, registro).then((data) => {
              console.log(`Registro exitoso wktramite`, data);
            });
            bandera = true;
          }
        }
      }
      if (bandera) {
        registrosConEstado.forEach((objeto) => {
          delete objeto.estado;
        });
      }

      for (const item of resultadoCombinado) {
        let investigador = {
          INV_fechaenvio: fechaEmpAn,
          INV_fechaasignacion: fechaEmpAn,
          IDTRABAJADOR: IdPersonaInv,
          TAINV_idtarifainvestigacion:
            idclasificacion === PLAESTA
              ? TARIFA_INV.SIMPLE
              : idclasificacion === PLACOMP
              ? TARIFA_INV.COMPLEJA
              : idclasificacion === PLAESPC
              ? TARIFA_INV.ESPECIAL
              : idclasificacion === PLAURG
              ? TARIFA_INV.URGENTE
              : null,
          RECT_idfolio: FolioRec,
          POL_idpoliza: item?.POL_idpoliza?.POL_idpoliza,
          INV_actualizofecha: fechaActSIN,
          INV_actualizousuario: nombreAnalista,
        };
        postInvestigador(investigador);
      }
      setVisible(true);
    } else {
      alert("Datos nulos");
    }
  };

  // Construir la descripción combinada con los datos de resultadoCombinado
  const generateDescription = () => {
    let description = "";
    resultadoCombinado.forEach((elemento, index) => {
      const numeroPosicion = index + 1;
      description += `Número de Siniestro ${numeroPosicion}: ${elemento?.NumSinigenerado}`;
    });
    return description;
  };

  const generateDescription2 = () => {
    let description = "";
    NoSiniestroAPI.forEach((numeroSiniestro, index) => {
      const numeroPosicion = index + 1;
      description += `Número de Siniestro ${numeroPosicion}: ${numeroSiniestro}\n`;
    });
    return description;
  };

  //Validar si existe un número de siniestro en la variable
  const noContieneNuloOEmptyString = NoSiniestroAPI.every(
    (valor) => valor !== null && valor !== ""
  );

  //Estados donde se guarda el mensaje de los datos del formulario
  const [msg1, setmsg1] = useState("");
  const [msg2, setmsg2] = useState("");
  const [msg3, setmsg3] = useState("");
  const [msg4, setmsg4] = useState("");
  const [msg5, setmsg5] = useState("");
  const [msg6, setmsg6] = useState("");
  const [msg7, setmsg7] = useState("");
  const [msg8, setmsg8] = useState("");
  const [msg9, setmsg9] = useState("");
  const [msg10, setmsg10] = useState("");
  const [msg11, setmsg11] = useState("");
  const [msg12, setmsg12] = useState("");
  const [msg13, setmsg13] = useState("");
  const [msg14, setmsg14] = useState("");
  const [msg15, setmsg15] = useState("");
  const [msg16, setmsg16] = useState("");

  //Validamos si estan nulos los datos
  useEffect(() => {
    if (reclamacion.trim() === "") {
      setmsg1(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg1("");
    }
    if (codigopostal.trim() === "") {
      setmsg2(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg2("");
    }
    if (SEPT_Id === "") {
      setmsg3(
        <FormattedMessage
          id="regisObliS"
          defaultMessage="/ Este campo es obligatorio"
        />
      );
    } else {
      setmsg3("");
    }
    if (calleDefuncion.trim() === "") {
      setmsg4(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg4("");
    }
    if (edadContratacion.trim() === "") {
      setmsg5(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg5("");
    }
    if (edadSiniestro.trim() === "") {
      setmsg6(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg6("");
    }
    if (nombreMedico.trim() === "") {
      setmsg7(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg7("");
    }
    if (apellidoPaterno.trim() === "") {
      setmsg8(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg8("");
    }
    if (apellidoMaterno.trim() === "") {
      setmsg9(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg9("");
    }
    if (cedula.trim() === "") {
      setmsg10(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg10("");
    }
    if (codpostal.trim() === "") {
      setmsg11(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg11("");
    }
    if (SEP_Id === "") {
      setmsg12(
        <FormattedMessage
          id="regisObliS"
          defaultMessage="/ Este campo es obligatorio"
        />
      );
    } else {
      setmsg12("");
    }
    if (calleMedico.trim() === "") {
      setmsg13(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg13("");
    }
    if (numExt.trim() === "") {
      setmsg14(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg14("");
    }
    if (numInt.trim() === "") {
      setmsg15(
        <FormattedMessage
          id="regisObli"
          defaultMessage="Este campo es obligatorio"
        />
      );
    } else {
      setmsg15("");
    }
    if (idclasificacion === "") {
      setmsg16(
        <FormattedMessage
          id="regisObliS"
          defaultMessage="/ Este campo es obligatorio"
        />
      );
    } else {
      setmsg16("");
    }
  }, [
    reclamacion,
    codigopostal,
    SEP_Id,
    calleDefuncion,
    edadContratacion,
    edadSiniestro,
    nombreMedico,
    apellidoPaterno,
    apellidoMaterno,
    cedula,
    codpostal,
    SEPT_Id,
    calleMedico,
    numExt,
    numInt,
    idclasificacion,
  ]);

  //Conocer los datos de las variables
  console.log(NoSiniestro);
  console.log(NoSiniestroAPI);
  console.log(Datos.PER_idpersona?.PER_idpersona);
  console.log(NoSiniestro);
  console.log(reclamacion);
  console.log(calleDefuncion);
  console.log(SEPT_Id);
  console.log(edadContratacion);
  console.log(edadSiniestro);
  console.log(idclasificacion);
  console.log(FolioRec);
  console.log(fechaActSIN);
  console.log(nombreMedico);
  console.log(apellidoPaterno);
  console.log(apellidoMaterno);
  console.log(cedula);
  console.log(calleMedico);
  console.log(numExt);
  console.log(numInt);
  console.log(SEP_Id);
  console.log(rolPersona);
  console.log(dias);

  console.log("/////////////////////Datos alert///////////");
  console.log(`Folio: ${Datos.RECT_idfolio?.RECT_numerofolio}`);
  console.log(
    `Nombre del Asegurado: ${
      Datos.PER_idpersona?.PER_nombre +
      " " +
      Datos.PER_idpersona?.PER_apellidopaterno +
      " " +
      Datos.PER_idpersona?.PER_apellidomaterno
    } `
  );
  console.log(`Fecha de creación: ${moment(fechaEmpAn).format(formatofecha)}`);
  console.log(`Hora de creación: ${horaActual}`);
  console.log(generateDescription());
  console.log(`Analista: ${nombreAnalista}`);
  console.log(`Dictaminador: ${NomDic}`);
  console.log(`Médico: ${NomMedic}`);

  //Metodo para que las variables puedan ser leidas
  const validateForm = (e) => {
    e.preventDefault();
    let charola = {
      CHREG_entregado: true,
      CHREG_fechaentregado: fechaActSIN,
      RECT_idfolio: FolioRec,
      PER_idpersona: IdPersonaApi,
      CHREG_actualizofecha: fechaActSIN,
      CHREG_actualizousuario: nombreAnalista,
    };

    let recepcion = {
      SINC_idcomplejidadsiniestro:
        Datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro
          ?.TRA_idtipotramitesiniestro,
      TEST_idestatustramite: estatusTramite?.TEST_idestatustramite,
      RECT_actualizofecha: fechaActSIN,
      RECT_actualizousuario: nombreAnalista,
    };

    if (
      Object.keys(NoSiniestro).length > 0 &&
      reclamacion != "" &&
      calleDefuncion != "" &&
      edadContratacion != "" &&
      edadSiniestro != "" &&
      idclasificacion != "" &&
      FolioRec != "" &&
      fechaActSIN != "" &&
      nombreMedico != "" &&
      apellidoPaterno != "" &&
      apellidoMaterno != "" &&
      cedula != "" &&
      calleMedico != "" &&
      rolPersona != ""
    ) {
      if (!noContieneNuloOEmptyString) {
        //ID a buscar
        const IDABuscar = [IDCAPDASINI, IDGENESINI, IDENVMED, IDENVDIC];
        objetosCoincidentes.forEach((objeto) => {
          if (IDABuscar.includes(objeto.TARE_idtarea.TARE_idtarea)) {
            objeto.estado = true;
          }
        });
        //Primer put a la tabla siniestros
        resultadoCombinado.forEach((siniestros) => {
          siniestro = {
            SIN_numerosiniestro: siniestros.NumSinigenerado,
            SIN_causadereclamacion: reclamacion,
            SIN_calle: calleDefuncion,
            SEP_codpostal: SEPT_Id,
            SIN_fuecremado: isOn,
            SIN_fueinhumado: isChecked,
            SIN_edadconcentracion: edadContratacion,
            SIN_edadsiniestro: edadSiniestro,
            SINC_idcomplejidadsiniestro: idclasificacion,
            RECT_idfolio: FolioRec,
            PAI_idpais: idpais,
            SIN_ciudadsiniestro: ciudadDefuncion,
            SIN_estadosiniestro: estadoDefuncion,
            SIN_cpsiniestro: codigoDefuncion,
            SIN_actualizofecha: fechaActSIN,
            SIN_actualizousuario: nombreAnalista,
          };
          putDataToken(
            `siniestros/get_id/${siniestros.SIN_idsiniestro}/`,
            siniestro
          ).then((data) => {
            console.log(`Registro ${siniestros.SIN_idsiniestro} actualizado`);
          });
          dataSiniestros.push(siniestro);
        });
        console.log(dataSiniestros);

        //Segundo insert del médico certificador
        let dataMedico;
        let personas = {
          PER_nombre: nombreMedico,
          PER_apellidopaterno: apellidoPaterno,
          PER_apellidomaterno: apellidoMaterno,
          PER_cedulaprofesional: cedula,
          PER_calle: calleMedico,
          PER_numeroexterior: idpais === IDPAISMEXICO ? numExt : null,
          PER_numerointerior: idpais === IDPAISMEXICO ? numInt : null,
          SEP_idsepomex: SEP_Id,
          PERR_idrol: rolPersona,
          PAI_idpais: idpais,
          PER_ciudadmedico: ciudadMedico,
          PER_estadomedico: estadoMedico,
          PER_cpmedico: codigoMedico,
          PER_actualizofecha: fechaActSIN,
          PER_actualizousuario: nombreAnalista,
        };
        console.log(personas);

        postDataToken(`personas/get_listado/`, personas).then((data) => {
          dataMedico = data;
          console.log(`Registro exitoso personas`, data);

          if (dataMedico) {
            polizastra.forEach((polizas) => {
              let personasxpoliza = {
                PER_idpersona: dataMedico?.PER_idpersona,
                POL_idpoliza: polizas?.POL_idpoliza?.POL_idpoliza,
                PXP_actualizofecha: fechaActSIN,
                PXP_actualizousuario: nombreAnalista,
              };
              postDataToken(
                `personasxpoliza/get_listado/`,
                personasxpoliza
              ).then((data) => {
                console.log(`Registro exitoso personasxpoliza`, data);
              });
            });
          }
        });

        //Cuarto post a charolamedico

        for (const item of resultadoCombinado) {
          let charolamedico = {
            RECT_idfolio: FolioRec,
            PER_idpersona: IdPersonaApi,
            SIN_idsiniestro: item?.SIN_idsiniestro,
            POL_idpoliza: item?.POL_idpoliza?.POL_idpoliza,
            CHMED_tiemporespuesta: dias,
            CHMED_fechatzrespentre: fechaActSIN,
            IDTRABAJADOR: IdPersonaMedico,
            CHMED_actualizofecha: fechaActSIN,
            CHMED_actualizousuario: nombreAnalista,
          };
          postCharolaMedico(charolamedico);
          let foliosiniestro = {
            RECT_idfolio: FolioRec,
            SIN_idsiniestro: item?.SIN_idsiniestro,
            POL_idpoliza: item?.POL_idpoliza?.POL_idpoliza,
            PER_idpersona: IdPersonaApi,
            IDTRABAJADOR: IdPersonaDic,
            PXFS_actualizofecha: fechaActSIN,
            PXFS_actualizousuario: nombreAnalista,
          };
          postFoliosiniestro(foliosiniestro);
        }

        //Quinto put a la charola registro
        putCharolaRegistro(charola);

        //Sexto put
        putRecepcion(recepcion);

        //Septimo put
        DatosComunicacion.forEach((Comunicacions) => {
          let Comunicacion = {
            RECT_idfolio: Comunicacions.RECT_idfolio.RECT_idfolio,
            PER_idpersona: Comunicacions.PER_idpersona.PER_idpersona,
            POL_idpoliza: Comunicacions.POL_idpoliza.POL_idpoliza,
            Usuario_Login: Comunicacions.Usuario_Login.id,
            CHCOM_fechaentregado: Comunicacions.CHCOM_fechaentregado,
            CHCOM_tiemporespuesta: dias,
            CHCOM_actualizofecha: fechaActSIN,
            CHCOM_actualizousuario: nombreAnalista,
          };
          putDataToken(
            `charolacomunicacion/get_id/${Comunicacions.CHCOM_idcharolacom}/`,
            Comunicacion
          ).then((data) => {
            console.log(
              `Registro ${Comunicacions.CHCOM_idcharolacom} actualizado`
            );
          });
        });

        // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
        const registrosConEstado = objetosCoincidentes.filter(
          (registro) => registro.estado
        );
        console.log(registrosConEstado);

        let bandera = false;

        for (const itempo of resultadoCombinado) {
          for (const itemTra of registrosConEstado) {
            for (const siniestro of tipificacionSiniestro) {
              let registro = {
                RECT_idfolio: FolioRec,
                POL_idpoliza: itempo.POL_idpoliza.POL_idpoliza,
                WTM_idwftareasxmodulos: itemTra.WTM_idwftareasxmodulos,
                PER_idpersona: IdPersonaApi,
                SIN_idsiniestro: siniestro?.SIN_idsiniestro,
                WKT_fechawktramite: fechaActSIN,
                WKT_seejecuto: itemTra.estado,
                WKT_actualizofecha: fechaActSIN,
                WKT_actualizousuario: nombreAnalista,
              };
              postDataToken(`wktramite/get_listado/`, registro).then((data) => {
                console.log(`Registro exitoso wktramite`, data);
              });
              bandera = true;
            }
          }
        }
        if (bandera) {
          registrosConEstado.forEach((objeto) => {
            delete objeto.estado;
          });
        }

        resultadoCombinado.forEach((polizas) => {
          const poliza = {
            POL_polizaindisputable: polizas.estatusdisputable,
            POL_actualizofecha: fechaActSIN,
            POL_actualizousuario: nombreAnalista,
          };
          putDataToken(
            `polizas/get_id/${polizas.POL_idpoliza.POL_idpoliza}/`,
            poliza
          ).then((data) => {
            console.log(
              `Registro de poliza ${polizas.POL_idpoliza.POL_idpoliza} actualizado`,
              data
            );
          });
        });

        //Datos que se mostraran en el dialog
        setTitle(
          <FormattedMessage
            id="regisAsgSi"
            defaultMessage="Asignación de número de siniestro"
          />
        );
        setdescription1(
          <FormattedMessage
            id="recepFolio"
            defaultMessage="Folio: {folio}"
            values={{ folio: Datos.RECT_idfolio?.RECT_numerofolio }}
          />
        );
        setdescription2(
          <FormattedMessage
            id="recepNomAseg"
            defaultMessage="Nombre del Asegurado: {nomAse}"
            values={{
              nomAse:
                Datos.PER_idpersona?.PER_nombre +
                " " +
                Datos.PER_idpersona?.PER_apellidopaterno +
                " " +
                Datos.PER_idpersona?.PER_apellidomaterno,
            }}
          />
        );
        setdescription3(
          <FormattedMessage
            id="recepFeCre"
            defaultMessage="Fecha de creación: {fecha}"
            values={{ fecha: moment(fechaEmpAn).format(formatofecha) }}
          />
        );
        setdescription4(
          <FormattedMessage
            id="regisHoraCrea"
            defaultMessage="Hora de creación: {hora}"
            values={{ hora: horaActual }}
          />
        );
        setdescription5(generateDescription());
        setdescription6(
          <FormattedMessage
            id="regisAnalista"
            defaultMessage="Analista: {analista}"
            values={{ analista: nombreAnalista }}
          />
        );
        setdescription7(
          <FormattedMessage
            id="regisDic"
            defaultMessage="Dictaminador: {dictaminador}"
            values={{ dictaminador: NomDic }}
          />
        );
        setdescription8(
          <FormattedMessage
            id="regisMedi"
            defaultMessage="Médico: {medico}"
            values={{ medico: NomMedic }}
          />
        );
      } else {
        alert("Valor duplicado" + NoSiniestroAPI);
        setvisibleboton("true");
        setTitle(
          <FormattedMessage
            id="regisReExist"
            defaultMessage="Ya existe un registro de siniestro con estos datos"
          />
        );
        setdescription1(
          <FormattedMessage
            id="recepFolio"
            defaultMessage="Folio: {folio}"
            values={{ folio: Datos.RECT_idfolio?.RECT_numerofolio }}
          />
        );
        setdescription2(
          <FormattedMessage
            id="recepNomAseg"
            defaultMessage="Nombre del Asegurado: {nomAse}"
            values={{
              nomAse:
                Datos.PER_idpersona?.PER_nombre +
                " " +
                Datos.PER_idpersona?.PER_apellidopaterno +
                " " +
                Datos.PER_idpersona?.PER_apellidomaterno,
            }}
          />
        );
        setdescription3(
          <FormattedMessage
            id="recepFeCre"
            defaultMessage="Fecha de creación: {fecha}"
            values={{ fecha: moment(fechaSin[0]).format(formatofecha) }}
          />
        );
        setdescription4(
          <FormattedMessage
            id="regisHoraCrea"
            defaultMessage="Hora de creación: {hora}"
            values={{ hora: moment(fechaSin[0]).format("HH:mm:ss") }}
          />
        );
        setdescription5(generateDescription2());

        setTimeout(() => {
          window.location.href = "/registro/inicio";
        }, 5000);
      }
    } else {
      setvisibleboton("true");
      setTitle(
        <FormattedMessage
          id="regisOcuError"
          defaultMessage="Ha ocurrido un error"
        />
      );
      setdescription1(
        <FormattedMessage
          id="regisIntTar"
          defaultMessage="Intentelo más tarde."
        />
      );
      setdescription2(
        <FormattedMessage
          id="regisRedTarde"
          defaultMessage="En un momento se redireccionara al apartado de inicio."
        />
      );
      setTimeout(() => {
        window.location.href = "/registro/inicio";
      }, 5000);
    }
  };
  console.log("Valor postMedico" + postMedico);

  return (
    <form onSubmit={validateForm}>
      <Box sx={{ m: 1, minWidth: 25 }}>
        {visibleInv && (
          <DialogInv
            title={
              <FormattedMessage
                id="msgGeCoSin"
                defaultMessage="Se generó correctamente el siniestro"
              />
            }
            description={
              <FormattedMessage
                id="regisPolCa"
                defaultMessage="La póliza es candidata para investigación"
              />
            }
            description2={
              <FormattedMessage
                id="regisEnvIng"
                defaultMessage="¿Desea enviarla a la investigación?"
              />
            }
            firstButton={<FormattedMessage id="globSi" defaultMessage="Si" />}
            secondButton={<FormattedMessage id="globNo" defaultMessage="No" />}
            onClick1={handleClickInvestigador}
            to2="/registro/inicio"
          />
        )}
        {visible && (
          <DialogInvestigador
            title={
              <FormattedMessage
                id="regisExitoso"
                defaultMessage="Registro exitoso"
              />
            }
            secondButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={
              <FormattedMessage
                id="regisInvAsingoMsg"
                defaultMessage="El investigador que se asigno es:"
              />
            }
            description2={NombreInv}
            to="/registro/inicio"
          />
        )}
        {visibleNoTurnInv && (
          <DialogInvestigador
            title={
              <FormattedMessage
                id="regisMsgInf"
                defaultMessage="Mensaje informativo"
              />
            }
            secondButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={
              <FormattedMessage
                id="regisMsgNoInv"
                defaultMessage="Este caso no necesita investigación."
              />
            }
            to="/registro/inicio"
          />
        )}
        {visibleContar && (
          <DialogAviso
            title={
              <FormattedMessage
                id="regisMsgInf"
                defaultMessage="Mensaje informativo"
              />
            }
            firstButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={
              <FormattedMessage
                id="RegiscertFall"
                defaultMessage="Contamos con {count} fallecimientos certificados por este médico."
                values={{ count }}
              />
            }
            onClick1={handleClick}
          />
        )}
        <Grid container>
          <Grid xs={12}>
            <h3-header-card>
              <FormattedMessage
                id="datosDaAse"
                defaultMessage="Datos del asegurado"
              />
            </h3-header-card>
          </Grid>
          <Grid xs={12}></Grid>
          <br />
          <br />
          <Grid xs={3.5}>
            <h3-title-card>
              <FormattedMessage
                id="datosNombs"
                defaultMessage="Nombre del asegurado"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {Datos?.PER_idpersona?.PER_nombre?.toUpperCase() +
                " " +
                Datos?.PER_idpersona?.PER_apellidopaterno?.toUpperCase() +
                " " +
                Datos?.PER_idpersona?.PER_apellidomaterno?.toUpperCase()}
            </h3-subtitle-card>
            <br />
          </Grid>
          <Grid xs={3.5}>
            <h3-title-card>
              <FormattedMessage
                id="datosTramite"
                defaultMessage="Tipo trámite"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {Datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro.toUpperCase()}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3.5}>
            <h3-title-card>
              <FormattedMessage id="dictamPoli" defaultMessage="Pólizas" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>{contenidoPolizas}</h3-subtitle-card>
          </Grid>
        </Grid>
        <hr />
        <br />
        <Grid container>
          <Grid xs={12}>
            <h3-header-card>
              <FormattedMessage
                id="regisSiRec"
                defaultMessage="Causas de la reclamación"
              />
            </h3-header-card>
            <br />
            <h3-subtitle-card>
              <FormattedMessage
                id="regisSiMot"
                defaultMessage="Motivo por el que él asegurado o beneficiario están presentando la reclamación"
              />
            </h3-subtitle-card>
            <TXTField
              multiline={true}
              id="reclamacion"
              name="reclamacion"
              value={reclamacion}
              helperText={msg1}
              onChange={(e) => setreclamacion(e.target.value)}
              onInput={(e) => LimitarTexto(e.target, 250)}
            />
            <br />
            <br />
          </Grid>
          <hr></hr>
          <Grid xs={12}>
            <br />
            <h3-header-card>
              <FormattedMessage
                id="regisSiDef"
                defaultMessage="Lugar de defunción"
              />
            </h3-header-card>
            <br />
          </Grid>
          <Grid xs={2.9}>
            <br />
            <h3-subtitle-card>
              <FormattedMessage
                id="regisSuSini"
                defaultMessage="¿En que país sucedió el siniestro?"
              />
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <Select1
              sx={{
                minWidth: 280,
                maxWidth: 280,
                height: 40,
                background: "white",
              }}
              array={paises}
              onChange={handleChangePais}
            />
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>
        {idpais === IDPAISMEXICO ? (
          <div>
            <Grid container>
              <Grid xs={2.7}>
                <TXTField
                  helperText={msg2}
                  label={
                    <FormattedMessage
                      id="globCodPos"
                      defaultMessage="Código postal"
                    />
                  }
                  value={codigopostal}
                  name="txtCP"
                  id="txtCP"
                  onChange={(event) => setCodigoPostal(event.target.value)}
                  size="small"
                  onKeyPress={handleKeyPress}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarDigitos(e.target, 6)}
                  showSearchIcon={true}
                  iconOnClick={handleSubmitone}
                />
              </Grid>
              <Grid xs={0.2}></Grid>
              <Grid xs={2.8}>
                <br />
                <div style={estiloDiv}>
                  <Select1
                    sx={{
                      minWidth: 150,
                      maxWidth: 315,
                      height: 40,
                      background: "white",
                    }}
                    label={
                      <FormattedMessage
                        id="globColoni"
                        defaultMessage={`Colonia {colonia}`}
                        values={{ colonia: msg3 }}
                      />
                    }
                    array={dataSelectColonia}
                    onChange={getInfoasentamiento}
                  />
                </div>
              </Grid>
              <Grid xs={0.2}></Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="regisAsent"
                        defaultMessage="asentamiento"
                      />
                    }
                    value={SEPT_asenta}
                    size="small"
                    container
                    spacing={1}
                  />
                </div>
              </Grid>
              <Grid xs={0.05}></Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globmunicipioAlcadia"
                        defaultMessage="municipio o Alcaldia"
                      />
                    }
                    value={SEPT_municipio}
                    size="small"
                    container
                    spacing={1}
                  />
                </div>
              </Grid>
              <Grid xs={2.85}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globestado"
                        defaultMessage="estado"
                      />
                    }
                    value={SEPT_estado}
                    size="small"
                    container
                    spacing={1}
                  />
                </div>
              </Grid>
              <Grid xs={0.1} />
              <Grid xs={2.86}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globciudad"
                        defaultMessage="ciudad"
                      />
                    }
                    value={SEPT_ciudad}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid xs={0.1} />
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage id="globCalle" defaultMessage="Calle" />
                    }
                    size="small"
                    id="calleDefuncion"
                    name="calleDefuncion"
                    value={calleDefuncion}
                    helperText={msg4}
                    onChange={(e) => setcalleDefuncion(e.target.value)}
                    onInput={(e) => LimitarTexto(e.target, 50)}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : idpais === "" ? (
          ""
        ) : (
          <div>
            <Grid container>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globDire"
                        defaultMessage="Dirección"
                      />
                    }
                    size="small"
                    id="calleDefuncion"
                    name="calleDefuncion"
                    value={calleDefuncion}
                    onChange={(e) => setcalleDefuncion(e.target.value)}
                    onInput={(e) => LimitarTextoSinCondiciones(e.target, 50)}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globCiudad"
                        defaultMessage="Ciudad"
                      />
                    }
                    size="small"
                    id="calleDefuncion"
                    name="calleDefuncion"
                    value={ciudadDefuncion}
                    onChange={(e) => setciudadDefuncion(e.target.value)}
                    onInput={(e) => LimitarTexto(e.target, 50)}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globEstado"
                        defaultMessage="Estado"
                      />
                    }
                    size="small"
                    id="calleDefuncion"
                    name="calleDefuncion"
                    value={estadoDefuncion}
                    onChange={(e) => setestadoDefuncion(e.target.value)}
                    onInput={(e) => LimitarTexto(e.target, 50)}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globCodPos"
                        defaultMessage="Código"
                      />
                    }
                    size="small"
                    id="Defuncion"
                    name="calleDefuncion"
                    value={codigoDefuncion}
                    onChange={(e) => setcodigoDefuncion(e.target.value)}
                    onInput={(e) => LimitarTextoCodigo(e.target, 12)}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <br />
        <br />
        <hr></hr>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <br />
            <h3-header-card>
              <FormattedMessage
                id="regisAdSin"
                defaultMessage="Información adicional del siniestro"
              />
            </h3-header-card>
          </Grid>
          <Grid item xs={"auto"}>
            <h3-subtitle-card>
              <FormattedMessage
                id="regisSiCre"
                defaultMessage="¿El cuerpo fue cremado?"
              />
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={"auto"}>
            <Check2
              checked={isOn}
              onChange={handleSwitchChange}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <h3-subtitle-card>
              <FormattedMessage
                id="regisSiInh"
                defaultMessage="¿El cuerpo fue inhumado?"
              />
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={"auto"}>
            <Check2
              checked={isChecked}
              onChange={handleCheckboxChange}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </Grid>
          <Grid item xs={0.2} />
        </Grid>
        <Grid container>
          <Grid xs={3.2}>
            <div class="espacios">
              <TXTField
                // id="contratacion"
                label={
                  <FormattedMessage
                    id="regisEdCon"
                    defaultMessage="Edad de contratación"
                  />
                }
                size="small"
                id="edadContratacion"
                name="edadContratacion"
                value={edadContratacion}
                helperText={msg5}
                onChange={(e) => setedadContratacion(e.target.value)}
                onKeyPress={handleKeyPress}
                onPaste={(e) => e.preventDefault()}
                onInput={(e) => LimitarDigitos(e.target, 2)}
              />
            </div>
          </Grid>
          <Grid xs={3.1}>
            <div class="espacios">
              <TXTField
                // id="siniestro"
                label={
                  <FormattedMessage
                    id="regisEdSin"
                    defaultMessage="Edad de siniestro"
                  />
                }
                size="small"
                id="edadSiniestro"
                name="edadSiniestro"
                value={edadSiniestro}
                helperText={msg6}
                onChange={(e) => setedadSiniestro(e.target.value)}
                onKeyPress={handleKeyPress}
                onPaste={(e) => e.preventDefault()}
                onInput={(e) => LimitarDigitos(e.target, 3)}
              />
            </div>
          </Grid>
          <Grid xs={0.2} />
        </Grid>
        <br />
        <br />
        <hr></hr>
        <Grid xs={12}>
          <br />
          <h3-header-card>
            <FormattedMessage
              id="regisMeCe"
              defaultMessage="Datos del médico que certifica"
            />
          </h3-header-card>
        </Grid>
        <Grid container>
          <Grid xs={3}>
            <div class="espacios">
              <TXTField
                label={
                  <FormattedMessage
                    id="datosNombs"
                    defaultMessage="Nombre(s)"
                  />
                }
                size="small"
                id="nombreMedico"
                name="nombreMedico"
                value={nombreMedico}
                helperText={msg7}
                onChange={(e) => setnombreMedico(e.target.value)}
                onKeyPress={handleKeyPressL}
                onPaste={(e) => e.preventDefault()}
                onInput={(e) => LimitarTexto(e.target, 50)}
              />
            </div>
          </Grid>
          <Grid xs={3}>
            <div class="espacios">
              <TXTField
                label={
                  <FormattedMessage
                    id="datosApPat"
                    defaultMessage="Apellido Paterno"
                  />
                }
                size="small"
                id="apellidoPaterno"
                name="apellidoPaterno"
                value={apellidoPaterno}
                helperText={msg8}
                onChange={(e) => setapellidoPaterno(e.target.value)}
                onKeyPress={handleKeyPressL}
                onPaste={(e) => e.preventDefault()}
                onInput={(e) => LimitarTexto(e.target, 50)}
              />
            </div>
          </Grid>
          <Grid xs={3}>
            <div class="espacios">
              <TXTField
                label={
                  <FormattedMessage
                    id="datosApMat"
                    defaultMessage="Apellido Materno"
                  />
                }
                size="small"
                id="apellidoMaterno"
                name="apellidoMaterno"
                value={apellidoMaterno}
                helperText={msg9}
                onChange={(e) => setapellidoMaterno(e.target.value)}
                onKeyPress={handleKeyPressL}
                onPaste={(e) => e.preventDefault()}
                onInput={(e) => LimitarTexto(e.target, 50)}
              />
            </div>
          </Grid>
          <Grid xs={3}>
            <div class="espacios">
              <TXTField
                label={
                  <FormattedMessage
                    id="globaCePro"
                    defaultMessage="Cédula Profesional"
                  />
                }
                size="small"
                id="cedula"
                name="cedula"
                value={cedula}
                helperText={msg10}
                onChange={(e) => setcedula(e.target.value)}
                onKeyPress={handleKeyPress}
                onPaste={(e) => e.preventDefault()}
                showSearchIcon={true}
                iconOnClick={handleSubmitcontar}
                onInput={(e) => LimitarDigitos(e.target, 15)}
              />
            </div>
          </Grid>
        </Grid>
        {idpais === IDPAISMEXICO ? (
          <div>
            <Grid container>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    helperText={msg11}
                    label={
                      <FormattedMessage
                        id="globCodPos"
                        defaultMessage="Código postal"
                      />
                    }
                    value={codpostal}
                    name="txtCP2"
                    id="txtCP2"
                    onChange={(event) => setCodPostal(event.target.value)}
                    size="small"
                    onKeyPress={handleKeyPress}
                    onPaste={(e) => e.preventDefault()}
                    onInput={(e) => LimitarDigitos(e.target, 6)}
                    showSearchIcon={true}
                    iconOnClick={handleSubmit}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <br />
                <div style={estiloDiv}>
                  <Select1
                    sx={{
                      minWidth: 150,
                      maxWidth: 325,
                      height: 40,
                      background: "white",
                    }}
                    label={
                      <FormattedMessage
                        id="globColoni"
                        defaultMessage={`Colonia {colonia}`}
                        values={{ colonia: msg12 }}
                      />
                    }
                    array={dataSelectColonias}
                    onChange={getInfoasenta}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="regisAsent"
                        defaultMessage="asentamiento"
                      />
                    }
                    value={SEP_asenta}
                    size="small"
                    container
                    spacing={1}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globmunicipioAlcadia"
                        defaultMessage="municipio o Alcaldia"
                      />
                    }
                    value={SEP_municipio}
                    size="small"
                    container
                    spacing={1}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globestado"
                        defaultMessage="estado"
                      />
                    }
                    value={SEP_estado}
                    size="small"
                    container
                    spacing={1}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globciudad"
                        defaultMessage="ciudad"
                      />
                    }
                    value={SEP_ciudad}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage id="globCalle" defaultMessage="Calle" />
                    }
                    size="small"
                    id="calleMedico"
                    name="calleMedico"
                    value={calleMedico}
                    onChange={(event) => setcalleMedico(event.target.value)}
                    helperText={msg13}
                    onInput={(e) => LimitarTexto(e.target, 100)}
                  />
                </div>
              </Grid>
              <Grid xs={1.5}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globaNoExt"
                        defaultMessage="No. ext"
                      />
                    }
                    size="small"
                    id="numExt"
                    name="numExt"
                    value={numExt}
                    onChange={(event) => setnumExt(event.target.value)}
                    helperText={msg14}
                    onKeyPress={handleKeyPress}
                    onPaste={(e) => e.preventDefault()}
                    onInput={(e) => LimitarDigitos(e.target, 5)}
                  />
                </div>
              </Grid>
              <Grid xs={1.5}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globaNoInt"
                        defaultMessage="No. int"
                      />
                    }
                    size="small"
                    id="numInt"
                    name="numInt"
                    value={numInt}
                    onChange={(event) => setnumInt(event.target.value)}
                    helperText={msg15}
                    onKeyPress={handleKeyPress}
                    onPaste={(e) => e.preventDefault()}
                    onInput={(e) => LimitarDigitos(e.target, 5)}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : idpais === "" ? (
          ""
        ) : (
          <div>
            <Grid container>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globDire"
                        defaultMessage="Dirección"
                      />
                    }
                    size="small"
                    id="calleDefuncion"
                    name="calleDefuncion"
                    value={calleMedico}
                    onChange={(event) => setcalleMedico(event.target.value)}
                    onInput={(e) => LimitarTextoSinCondiciones(e.target, 100)}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globCiudad"
                        defaultMessage="Ciudad"
                      />
                    }
                    size="small"
                    id="calleDefuncion"
                    name="calleDefuncion"
                    value={ciudadMedico}
                    onChange={(e) => setciudadMedico(e.target.value)}
                    onInput={(e) => LimitarTexto(e.target, 50)}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globEstado"
                        defaultMessage="Estado"
                      />
                    }
                    size="small"
                    id="calleDefuncion"
                    name="calleDefuncion"
                    value={estadoMedico}
                    onChange={(e) => setestadoMedico(e.target.value)}
                    onInput={(e) => LimitarTexto(e.target, 50)}
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div class="espacios">
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globCodPos"
                        defaultMessage="Código"
                      />
                    }
                    size="small"
                    id="Defuncion"
                    name="calleDefuncion"
                    value={codigoMedico}
                    onChange={(e) => setcodigoMedico(e.target.value)}
                    onInput={(e) => LimitarTextoCodigo(e.target, 12)}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <Grid xs={3}>
          <Select1
            sx={{
              minWidth: 290,
              maxWidth: 290,
              height: 40,
              background: "white",
            }}
            label={
              <FormattedMessage
                id="dictamClasV"
                defaultMessage={`Clasificación {clasi}`}
                values={{ clasi: msg16 }}
              />
            }
            array={clasificacion}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={"auto"}>
            <ButtonVino
              estatus={false}
              label={
                <FormattedMessage id="globCance" defaultMessage="Cancelar" />
              }
              enlace="/registro/inicio"
            />
          </Grid>
          <Grid item xs={"auto"}>
            {idpais === IDPAISMEXICO ? (
              <DialogCascadeTwoButtons
                mainButton={
                  <FormattedMessage
                    id="regisGenSi"
                    defaultMessage="Generar Siniestro"
                  />
                }
                type="submit"
                title={Title}
                description1={description1}
                description2={description2}
                description3={description3}
                description4={description4}
                horaActual
                description5={description5}
                description6={description6}
                description7={description7}
                description8={description8}
                visible={visibleboton}
                button={
                  <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
                }
                estatus={
                  reclamacion.length > 0 &&
                  codigopostal.length > 0 &&
                  SEP_Id > 0 &&
                  calleDefuncion.length > 0 &&
                  edadContratacion.length > 0 &&
                  edadSiniestro.length > 0 &&
                  nombreMedico.length > 0 &&
                  apellidoPaterno.length > 0 &&
                  apellidoMaterno.length > 0 &&
                  cedula.length > 0 &&
                  codpostal.length > 0 &&
                  SEPT_Id > 0 &&
                  calleMedico.length > 0 &&
                  numExt.length > 0 &&
                  numInt.length > 0 &&
                  idclasificacion > 0
                    ? false
                    : true
                }
                onClickAceptar={handleClickMedico}
              />
            ) : (
              <DialogCascadeTwoButtons
                mainButton={
                  <FormattedMessage
                    id="regisGenSi"
                    defaultMessage="Generar Siniestro"
                  />
                }
                type="submit"
                title={Title}
                description1={description1}
                description2={description2}
                description3={description3}
                description4={description4}
                horaActual
                description5={description5}
                description6={description6}
                description7={description7}
                description8={description8}
                visible={visibleboton}
                button={
                  <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
                }
                estatus={
                  reclamacion.length > 0 &&
                  edadContratacion.length > 0 &&
                  edadSiniestro.length > 0 &&
                  nombreMedico.length > 0 &&
                  apellidoPaterno.length > 0 &&
                  apellidoMaterno.length > 0 &&
                  cedula.length > 0 &&
                  idclasificacion > 0
                    ? false
                    : true
                }
                onClickAceptar={handleClickMedico}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
