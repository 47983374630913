/* Brandon Hernandez Rocha 11/2022 */
import React from "react";

import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CheckGroup from "../Tools/CheckBox/CheckGroup";

//Stepper
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment-timezone";

//Radio button
import RadioDinamico from "../Tools/RadioButton/RadioDinamico";
//Dialog Enviar
import DialogButton from "../Tools/Dialog/DialogButton";
import VerCarta from "./Elements/Cartas/VerCartas";

//tables
import TableCartaAsegurados from "./Elements/Tables/TablaCartaAsegurados";
import TableCartaBeneficiarios from "./Elements/Tables/TablaCartaBeneficiarios";
import TableCartaAgentes from "./Elements/Tables/TablaCartaAgentes";
import TableCartaOtros from "./Elements/Tables/TablaCartaOtros";
import TableCartaRechazo from "./Elements/Tables/TablaCartaRechazo";
import TableCartaRescision from "./Elements/Tables/TablaCartaRescision";
import TableCartaSolicitudDoc from "./Elements/Tables/TablaCartaSolicitudDoc";

//imagenes íconos
import img_recepcion from "../../img/menu/Recepcion.svg";
import img_recepcion_on from "../../img/menu/Recepcion_on.svg";
//
//Cartas
import { imprimirCarta } from "./Elements/Cartas/CartasDictamen";
//idioma
import { FormattedMessage } from "react-intl";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import { useState } from "react";
import { useEffect } from "react";
import {
  IDCARTARECHA,
  IDCARTARESCI,
  IDMODDICTAMEN,
  SOLICITUDDOC,
  ESTADOCARTAWF,
  IDTAREACARTADICT,
} from "../../utils/constanstoken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import StyledTableCell2 from "../Tools/Table/StyledTableCell2";
import { URLAPIIMG } from "../../utils/constans";
import getDate from "../Registro/Function/obtenerFecha";
import getHours from "../Registro/Function/obtenerHora";
import { getDataToken, postDataToken } from "../../api/dataToken";
import { generatePDFWithFetchSin } from "../../api/reportes";
import PDFViewer from "./PDF/PDFViewer";
import IconVisualizar from "../Tools/Icons/IconVisualizar";
import { ROLAGEN } from "../../utils/constanstoken";
import GetTareas from "../../api/getTareas";
import { uploadCartasToNextcloud } from "../../nextcloud/functions";
const CompGenerarCarta = () => {
  const steps = [
    "Agregar pólizas",
    "Selecciona destinatario(s)",
    "Selecciona carta",
    "Confirmación",
  ];

  const location = useLocation();
  const infoRow = location.state.row;
  const dataPolizas = location.state.polizas;
  console.log("dataPolizas", dataPolizas);
  const row = infoRow.infoFolio;
  console.log("location:", location);
  console.log("infoRow:", infoRow);
  let jsonCarta;

  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio",
      state: { row: dataPolizas },
    },
  ];
  //Array para radio button
  const arrayRb = [
    { id: 1, value: 1, label: "Asegurados" },
    { id: 2, value: 2, label: "Beneficiarios" },
    { id: 3, value: 3, label: "Agentes" },
    { id: 4, value: 4, label: "Otros" },
  ];
  const [rbValue, setRbValue] = React.useState(1);

  //ESTADOS GENERALES PARA GENERAR UNA CARTA
  const [destinatarios, setDestinatarios] = React.useState([]);
  const [cartas, setCartas] = React.useState([]);
  const [tipoCarta, setTipoCarta] = React.useState("");
  const [arrayDocs, setArrayDocs] = React.useState([]);
  const [visible, setvisible] = React.useState(false);
  const [agentes, setagentes] = useState([]);
  console.log(tipoCarta);

  React.useEffect(() => {
    setArrayDocs([]);
    cartas.map((carta) => {
      setArrayDocs((prevState) => [...prevState, carta]);
    });
  }, [cartas]);

  React.useEffect(() => {
    console.log(arrayDocs);
  }, [arrayDocs]);

  //Funcion checkbox para seleccionar beneficiarios
  const [listaPolizas, setListaPolizas] = React.useState([
    {
      poliza: infoRow?.POL_idpoliza?.POL_numpoliza,
      idPoliza: infoRow?.POL_idpoliza?.POL_idpoliza,
      tramite:
        infoRow?.RECT_idfolio?.SINC_idcomplejidadsiniestro
          ?.TRA_idtipotramitesiniestro,
    },
  ]);
  const [listaPolizasFormat, setListaPolizasFormat] = React.useState([
    infoRow?.POL_idpoliza?.POL_numpoliza,
  ]);

  //Obtenemos las tareas para nuestro módulo
  const objetosCoincidentes = GetTareas(IDMODDICTAMEN);
  console.log(objetosCoincidentes);

  /////DATOS LOGIN ACTUALIZO FECHA Y USUARIO////
  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(fechaFormateada);
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  console.log("fechaActSIN" + fechaActSIN);

  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idAnalista, setidAnalista] = React.useState("");
  const [datosConf, setdatosConf] = React.useState([]);

  //Obtener usuario.
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setidAnalista(result.id);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
      var resultConf = await getDataToken(`configuraciongeneral/get_id/1/`);
      setdatosConf(resultConf);
      const resultadosObtenidos = [];
      for (const polizas of listaPolizas) {
        var resultadoPoliza = await getDataToken(
          `personasxpoliza/get_poliza/${polizas.idPoliza}/`
        );
        resultadosObtenidos.push(resultadoPoliza);
      }
      const resultadosCombinados = [].concat(...resultadosObtenidos);

      setagentes(resultadosCombinados);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, [listaPolizas]);

  const agentesData = agentes.filter(
    (registro) =>
      registro.PER_idpersona?.PERR_idrol?.PERR_rolpersona?.toLowerCase() ===
      ROLAGEN
  );

  const nombresAgentes = agentesData.map((agente) => ({
    nombreAgente:
      agente.PER_idpersona?.PER_nombre +
      " " +
      agente.PER_idpersona?.PER_apellidopaterno +
      " " +
      agente.PER_idpersona?.PER_apellidomaterno,
    numPoliza: agente.POL_idpoliza?.POL_numpoliza,
  }));

  console.log("Nombres de los agentes:", nombresAgentes);
  console.log(nombreAnalista);

  ///////////////////////////
  //Estados Cartas//////////

  const [pdfArray, setPdfArray] = useState([]);
  const [currentDate, setCurrentDate] = useState("");

  //Obtener fecha
  useEffect(() => {
    const formattedDate = moment().format("LL"); // Formato "Ciudad de México a D [de] MMMM [de] YYYY"
    setCurrentDate(formattedDate);
  }, []);

  //funcion para añadir una poliza a la lista
  const updatePolizaToList = (e, item) => {
    if (e.target.checked) {
      setListaPolizas((prevState) => [
        ...prevState,
        {
          poliza: item?.POL_idpoliza?.POL_numpoliza,
          idPoliza: item?.POL_idpoliza?.POL_idpoliza,
          tramite:
            item?.RECT_idfolio?.SINC_idcomplejidadsiniestro
              ?.TRA_idtipotramitesiniestro,
        },
      ]);
      setListaPolizasFormat((prevState) => [
        ...prevState,
        item?.POL_idpoliza?.POL_numpoliza,
      ]);
    } else {
      //remove from array
      setListaPolizas((current) =>
        current.filter(
          (poliza) =>
            poliza.poliza !== item.POL_idpoliza.POL_numpoliza && item != infoRow
        )
      );
      setListaPolizasFormat((current) =>
        current.filter(
          (poliza) =>
            poliza !== item?.POL_idpoliza?.POL_numpoliza && item != infoRow
        )
      );
    }
  };
  React.useEffect(() => {
    console.log("polizas seleccionadas", listaPolizas);
    console.log("polizas seleccionadas FORMAT", listaPolizasFormat);
    // listaPolizas.length>0 ? setDisabledButton(false) : setDisabledButton(true)
  }, [listaPolizas]);
  // React.useEffect(() => {
  // if(tipoCarta===2){
  // habilitarBoton(false)
  // }
  // })

  //Cambiar imagenes on a off y viceversa
  const [img2, setImg2] = React.useState(false);
  const [img3, setImg3] = React.useState(false);
  const [img4, setImg4] = React.useState(false);

  //Show/hide tables

  //Buttons
  const [disabledButton, setDisabledButton] = React.useState(false);

  //Stepper functions
  const [activeStep, setActiveStep] = React.useState(0);

  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 0;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 1) setDisabledButton(true);
    if (img3) habilitarBoton(false);

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  console.log(activeStep);
  console.log(disabledButton);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //setRbValue(1)
    if (activeStep === 1) {
      setListaPolizas([]);
      setDisabledButton(false);
    } else if (activeStep === 3) setDisabledButton(false);
    else setDisabledButton(true);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const habilitarBoton = (value) => {
    setDisabledButton(value);
  };

  //Apartado para destinatarios, funciones y estados
  const [destinatariosFormat, setDestinatariosFormat] = useState([]);

  useEffect(() => {
    if (destinatarios.length > 0) {
      // Usar map() para crear un nuevo arreglo de objetos con los campos deseados
      const nuevoArreglo = destinatarios.map((item) => ({
        id: item.PXP_idpersonaxpoliza,
        idPersona: item?.PER_idpersona?.PER_idpersona,
        nombre: item?.PER_idpersona?.PER_nombre,
        apePat: item?.PER_idpersona?.PER_apellidopaterno,
        apeMat: item?.PER_idpersona?.PER_apellidomaterno,
        calleynum: `${item.PER_idpersona?.PER_calle} No. Ext. ${item.PER_idpersona?.PER_numeroexterior} No. Int. ${item.PER_idpersona?.PER_numerointerior}`,
        colonia: `Col. ${item.PER_idpersona?.SEP_idsepomex?.SEP_asenta}`,
        cp: `CP. ${item.PER_idpersona?.SEP_idsepomex?.SEP_codpostal}`,
        alcaldia: `Alcaldía ${item.PER_idpersona?.SEP_idsepomex?.SEP_municipio}`,
        polizas: [
          {
            id: item?.POL_idpoliza?.POL_idpoliza,
            numPoliza: item?.POL_idpoliza?.POL_numpoliza,
          },
        ],
      }));

      nuevoArreglo.forEach((itemNuevo) => {
        const match = nombresAgentes.find(
          (agente) => agente.numPoliza === itemNuevo.polizas[0]?.numPoliza
        );
        if (match) {
          // Si hay coincidencia, agregar la información al objeto en nuevoArreglo
          itemNuevo.nombreAgente = match.nombreAgente;
        }
      });
      // Actualizar el estado con el nuevo arreglo
      setDestinatariosFormat(nuevoArreglo);
    }
  }, [destinatarios]);

  console.log(destinatariosFormat);

  const seleccionarDestinatarios = () => {
    return (
      <React.Fragment>
        {/* Tabla destinatarios */}
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
          marginTop={"20px"}
        >
          <Grid item>
            <RadioDinamico
              defaultValue={rbValue}
              array={arrayRb}
              row={true}
              onChange={(e) => setRbValue(e.target.value)}
            />
          </Grid>
        </Grid>
        {rbValue != 1 ? null : (
          <Grid item xs={12} style={{ marginTop: "30px" }}>
            <TableCartaAsegurados
              onDataChange={habilitarBoton}
              destinatarios={(e) => setDestinatarios(e)}
              idPoliza={listaPolizas}
            />
          </Grid>
        )}
        {rbValue != 2 ? null : (
          <Grid item xs={12} style={{ marginTop: "30px" }}>
            <TableCartaBeneficiarios
              onDataChange={habilitarBoton}
              destinatarios={(e) => setDestinatarios(e)}
              idPoliza={listaPolizas}
            />
          </Grid>
        )}
        {rbValue != 3 ? null : (
          <Grid item xs={12} style={{ marginTop: "30px" }}>
            <TableCartaAgentes
              onDataChange={habilitarBoton}
              destinatarios={(e) => setDestinatarios(e)}
              idPoliza={listaPolizas}
            />
          </Grid>
        )}
        {rbValue != 4 ? null : (
          <Grid item xs={12} style={{ marginTop: "30px" }}>
            <TableCartaOtros
              onDataChange={habilitarBoton}
              destinatarios={(e) => setDestinatarios(e)}
              idPoliza={listaPolizas}
              informacion={dataPolizas}
            />
          </Grid>
        )}
      </React.Fragment>
    );
  };
  /**
   *
   * @returns ESTA FUNCION RETORNA LOS ÍCONOS DE LAS CARTAS ASÍ COMO TAMBIEN SUS RESPECTIVAS TABLAS
   */
  const seleccionarCarta = () => {
    return (
      <React.Fragment>
        {/* ÍCONOS */}
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          className="grid-container-generar-carta"
        >
          {/* ÍCONO CARTA RECHAZO */}
          <Grid
            item
            xs={3}
            className="grid-item-generar-carta"
            onClick={() => {
              setImg2(true);
              setImg3(false);
              setImg4(false);
            }}
          >
            {!img2 ? (
              <img
                className="img-iconos-dictamen"
                src={img_recepcion}
                alt="Recepción"
              />
            ) : (
              <img
                className="img-iconos-dictamen"
                src={img_recepcion_on}
                alt="Recepción"
              />
            )}
            {!img2 ? (
              <dictamen-icono-carta-off>
                <FormattedMessage
                  id="globCartaRec"
                  defaultMessage="Carta Rechazo"
                />
              </dictamen-icono-carta-off>
            ) : (
              <dictamen-icono-carta-on>
                <FormattedMessage
                  id="globCartaRec"
                  defaultMessage="Carta Rechazo"
                />
              </dictamen-icono-carta-on>
            )}
          </Grid>
          {/* ÍCONO CARTA RESCISIÓN */}
          <Grid
            item
            xs={3}
            className="grid-item-generar-carta"
            onClick={() => {
              setImg3(true);
              setImg2(false);
              setImg4(false);
              setTipoCarta(2);
              habilitarBoton(false);
            }}
          >
            {!img3 ? (
              <img
                className="img-iconos-dictamen"
                src={img_recepcion}
                alt="Recepción"
              />
            ) : (
              <img
                className="img-iconos-dictamen"
                src={img_recepcion_on}
                alt="Recepción"
              />
            )}
            {!img3 ? (
              <dictamen-icono-carta-off>
                <FormattedMessage
                  id="globCartaRes"
                  defaultMessage="Carta Rescisión"
                />
              </dictamen-icono-carta-off>
            ) : (
              <dictamen-icono-carta-on>
                <FormattedMessage
                  id="globcartaRes"
                  defaultMessage="Carta Rescisión"
                />
              </dictamen-icono-carta-on>
            )}
          </Grid>
          {/* ÍCONO SOLICITUD DE DOCUMENTACIÓN */}
          <Grid
            item
            xs={3}
            className="grid-item-generar-carta"
            onClick={() => {
              setImg3(false);
              setImg2(false);
              setImg4(true);
            }}
          >
            {!img4 ? (
              <img
                className="img-iconos-dictamen"
                src={img_recepcion}
                alt="Recepción"
              />
            ) : (
              <img
                className="img-iconos-dictamen"
                src={img_recepcion_on}
                alt="Recepción"
              />
            )}
            {!img4 ? (
              <dictamen-icono-carta-off>
                <FormattedMessage
                  id="dictaSoliDoc"
                  defaultMessage="Solicitud de documentación"
                />
              </dictamen-icono-carta-off>
            ) : (
              <dictamen-icono-carta-on>
                <FormattedMessage
                  id="dictaSoliDoc"
                  defaultMessage="Solicitud de documentación"
                />
              </dictamen-icono-carta-on>
            )}
          </Grid>
        </Grid>

        {/* TABLAS DE LAS CARTAS*/}
        <Grid container direction="row" alignItems="center">
          {!img2 ? null : (
            <Grid item xs={12}>
              <TableCartaRechazo
                onDataChange={habilitarBoton}
                cartas={(e) => setCartas(e)}
                tipoCarta={(e) => setTipoCarta(e)}
              />
            </Grid>
          )}
          {!img3 ? null : (
            <Grid item xs={12}>
              <TableCartaRescision
                onDataChange={habilitarBoton}
                cartas={(e) => setCartas(e)}
                tipoCarta={(e) => setTipoCarta(e)}
              />
            </Grid>
          )}
          {!img4 ? null : (
            <Grid item xs={12}>
              <TableCartaSolicitudDoc
                onDataChange={habilitarBoton}
                cartas={(e) => setCartas(e)}
                tipoCarta={(e) => setTipoCarta(e)}
                listaPolizas={listaPolizas}
              />
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  };
  /**
   *
   * @returns ESTA FUNCION RETORNA LAS POLIZAS ASOCIADAS AL FOLIO, SIN CONTAR CON LA PÓLIZA YA SELECCIONADA ANTERIORMENTE EN DETALLE
   */
  const agregarPolizas = () => {
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <h3-title-card>
              <FormattedMessage
                id="dictaAgrePo"
                defaultMessage="Agregar pólizas:"
              />
            </h3-title-card>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={20}
        >
          {dataPolizas.map((poliza) => {
            console.log(poliza);
            if (
              poliza.POL_idpoliza.POL_idpoliza !=
              infoRow.POL_idpoliza.POL_idpoliza
            ) {
              return (
                <Grid item>
                  <CheckGroup
                    onChange={(e) => updatePolizaToList(e, poliza)}
                    label={poliza?.POL_idpoliza?.POL_numpoliza}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item>
                  <CheckGroup
                    onChange={(e) => updatePolizaToList(e, poliza)}
                    label={poliza?.POL_idpoliza?.POL_numpoliza}
                    checked={true}
                    disabled={true}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </React.Fragment>
    );
  };
  const confirmarDatosCarta = () => {
    {
      /* APARTADO PARA LLENAR EL ARREGLO DE LAS PÓLIZAS SELECCIONADAS */
    }

    console.log(listaPolizas);
    let polizasLista = [];
    listaPolizas.map((polizas, index) => {
      index === 0
        ? polizasLista.push(polizas.poliza)
        : polizasLista.push(polizas.poliza);
    });
    //Agregamos las polizas a data
    //Data contendrá los datos necesarios para el documento
    let data = {
      arrayDocs: arrayDocs,
      destinatarios: destinatariosFormat,
      polizas: polizasLista,
    };

    let arrDocs = arrayDocs.map((element) => {
      return { causa: element?.FK_IDCausa?.CAU_descripcion };
    });

    console.log(data);

    jsonCarta = {
      rutalogo: `${URLAPIIMG}${datosConf?.SCG_imgPathSing}`,
      rutaimg: `${URLAPIIMG}${datosConf?.SCG_imgPathSubLogo}`,
      param_pie: `${URLAPIIMG}${datosConf?.SCG_imgPathPiePagina}`,
      param_firma: `${URLAPIIMG}${datosConf?.SCG_imgFirma}`,
      p: `${datosConf?.SCG_tituloPDF}`,
      p1: `${datosConf?.SCG_estadoCarta} a ${currentDate}`,
      p6: `Ref. Asegurada/asegurado ${dataPolizas[0]?.PER_idpersona?.PER_nombre} ${dataPolizas[0]?.PER_idpersona?.PER_apellidopaterno} ${dataPolizas[0]?.PER_idpersona?.PER_apellidomaterno}`,
      p8: `No. Folio ${dataPolizas[0]?.RECT_idfolio?.RECT_numerofolio}`,
      p10: `${datosConf?.SCG_acronimoAseguradora}`,
      p12: `${datosConf?.SCG_nombreFirma}`,
      p13: `${datosConf?.SCG_Puesto}`,
      p14: `${datosConf?.SCG_nombreLargoAseg}`,
      p16: `${datosConf?.SCG_direccionAseguradora}`,
      p17: JSON.stringify(arrDocs),
      tel: `${datosConf?.SCG_telefono}`,
      Pdoc: JSON.stringify(arrayDocs),
    };

    console.log(jsonCarta);
    console.log(visible);

    return (
      <React.Fragment>
        {/* Confirmacion de datos */}
        <Paper sx={{ width: "100%" }}>
          <TableContainer
            sx={{ maxHeight: 540 }}
            style={{ position: "sticky" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell2 align="center">
                    <FormattedMessage
                      id="dictamDest"
                      defaultMessage="Destinatarios"
                    />
                    {/* Contenido de Destinatarios */}
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <FormattedMessage
                      id="dictamPoli"
                      defaultMessage="Pólizas"
                    />
                    {/* Contenido de Pólizas */}
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <FormattedMessage
                      id="dictaTipoCart"
                      defaultMessage="Tipo carta"
                    />
                    {/* Contenido de Tipo de Carta */}
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <FormattedMessage
                      id="dictaVisImpr"
                      defaultMessage="Visualizar / Imprimir"
                    />
                    {/* Contenido de Visualizar / Imprimir */}
                  </StyledTableCell2>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell2 align="center">
                    <dl>
                      {destinatariosFormat.map((item) => {
                        return (
                          <>
                            <h3-subtitle-card>
                              {`${item.nombre} ${item.apePat} ${item.apeMat}`}{" "}
                            </h3-subtitle-card>
                          </>
                        );
                      })}
                    </dl>
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    {polizasLista.map((item) => {
                      return (
                        <>
                          <h3-subtitle-card>{item} </h3-subtitle-card>
                        </>
                      );
                    })}
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <dl>
                      <h3-subtitle-card>
                        {tipoCarta === IDCARTARECHA
                          ? "Carta rechazo"
                          : tipoCarta === IDCARTARESCI
                          ? "Carta rescisión"
                          : "Carta solicitud de documentos"}
                      </h3-subtitle-card>
                    </dl>
                  </StyledTableCell2>
                  <StyledTableCell2>
                    <dl style={{ textAlign: "center" }}>
                      <IconVisualizar
                        tooltipTitle="Visualizar PDF"
                        onClick={handlePDFGeneration}
                      />
                    </dl>
                  </StyledTableCell2>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Paper style={{ width: "100%" }}>
          <TableContainer style={{ position: "sticky" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell2>
                    {tipoCarta === IDCARTARECHA
                      ? `Causas`
                      : tipoCarta === IDCARTARESCI
                      ? `Causas`
                      : `Documentos solicitados`}
                    {/* Contenido de Causas o Documentos Solicitados */}
                  </StyledTableCell2>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell2>
                    <dl>
                      {tipoCarta !== 3
                        ? arrayDocs.map((item) => (
                            <React.Fragment key={item.id}>
                              <dt>
                                <h3-subtitle-card>
                                  -{item.FK_IDCausa.CAU_descripcion}{" "}
                                </h3-subtitle-card>
                              </dt>
                              <br />
                            </React.Fragment>
                          ))
                        : cartas.map((item) => {
                            let notas = item.texto ? item.texto : "Sin notas";
                            let tipo = item.tipo;

                            return (
                              <React.Fragment key={item.id}>
                                <dt>
                                  <h3-subtitle-card>
                                    {item.descripcion}{" "}
                                  </h3-subtitle-card>
                                </dt>
                                <br />
                                <dd>
                                  <h3-subtitle-card>
                                    - Tipo: {tipo}{" "}
                                  </h3-subtitle-card>
                                </dd>
                                <br />
                                <dd>
                                  <h3-subtitle-card>
                                    - Notas: {notas}{" "}
                                  </h3-subtitle-card>
                                </dd>
                                <br />
                              </React.Fragment>
                            );
                          })}
                    </dl>
                  </StyledTableCell2>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </React.Fragment>
    );
  };
  /**
   *
   * @param {Object} doc Recibe un objeto con datos para imprimir cierto tipo de carta
   */

  const guardarCartaNextcloud = async (
    tipoCarta,
    jsonCarta,
    destinatariosFormat
  ) => {
    //ruta de la carta rechazo
    let url;
    let pdfArray = [];
    let json;

    try {
      for (const destinatario of destinatariosFormat) {
        if (tipoCarta === IDCARTARECHA) {
          url = `templatesCartas/generate-cartarechazo/`;
          json = {
            param_logo: jsonCarta.rutalogo,
            param_imagen: jsonCarta.rutaimg,
            param_pie: jsonCarta.param_pie,
            param_firma: jsonCarta.param_firma,
            param: jsonCarta.p,
            param1: jsonCarta.p1,
            param2: destinatario.calleynum,
            param3: destinatario.colonia,
            param4: destinatario.cp,
            param5: destinatario.alcaldia,
            param6: jsonCarta.p6,
            param7: JSON.stringify(destinatario.polizas),
            param8: jsonCarta.p8,
            param9:
              destinatario.nombre +
              " " +
              destinatario.apePat +
              " " +
              destinatario.apeMat,
            param10: jsonCarta.p10,
            param11: "FALTA DATO",
            param12: jsonCarta.p12,
            param13: jsonCarta.p13,
            param14: jsonCarta.p14,
            param15: destinatario.nombreAgente,
            param16: jsonCarta.p16,
            param17: jsonCarta.p17,
          };
        } else if (tipoCarta === IDCARTARESCI) {
          url = `templatesCartas/generate-cartarescision/`;
          json = {
            param_logo: jsonCarta.rutalogo,
            param_imagen: jsonCarta.rutaimg,
            param_pie: jsonCarta.param_pie,
            param_firma: jsonCarta.param_firma,
            param: jsonCarta.p,
            param1: jsonCarta.p1,
            param2: destinatario.calleynum,
            param3: destinatario.colonia,
            param4: destinatario.cp,
            param5: destinatario.alcaldia,
            param6: jsonCarta.p6,
            param7: JSON.stringify(destinatario.polizas),
            param8: jsonCarta.p8,
            param9:
              destinatario.nombre +
              " " +
              destinatario.apePat +
              " " +
              destinatario.apeMat,
            param10: jsonCarta.p10,
            param11:
              destinatario.nombre +
              " " +
              destinatario.apePat +
              " " +
              destinatario.apeMat,
            param12: jsonCarta.tel,
            param13: jsonCarta.p12,
            param14: jsonCarta.p13,
            param15: jsonCarta.p14,
            param16: destinatario.nombreAgente,
            param17: jsonCarta.p16,
          };
        } else {
          url = `templatesCartas/generate-cartasoldoc/`;
          json = {
            param_logo: jsonCarta.rutalogo,
            param_imagen: jsonCarta.rutaimg,
            param_pie: jsonCarta.param_pie,
            param_firma: jsonCarta.param_firma,
            param: jsonCarta.p,
            param1: jsonCarta.p1,
            param2: destinatario.calleynum,
            param3: destinatario.colonia,
            param4: destinatario.cp,
            param5: destinatario.alcaldia,
            param6: jsonCarta.p6,
            param7: JSON.stringify(destinatario.polizas),
            param8: jsonCarta.p8,
            param9:
              destinatario.nombre +
              " " +
              destinatario.apePat +
              " " +
              destinatario.apeMat,
            param10: jsonCarta.p10,
            param11: jsonCarta.Pdoc,
            param12: jsonCarta.p12,
            param13: jsonCarta.p13,
            param14: jsonCarta.p14,
            param15: destinatario.nombreAgente,
            param16: jsonCarta.p16,
          };
        }
        console.log(json);
        const resultadoCartas = await generatePDFWithFetchSin(url, json);
        pdfArray.push(resultadoCartas);
        console.log(pdfArray); // Accede a la URL y otros datos
      }
      setPdfArray(pdfArray);
    } catch (error) {
      console.error("Error al generar el PDF o cargarlo en Nextcloud:", error);
    }
  };

  // Función para manejar el clic del botón
  const handlePDFGeneration = () => {
    guardarCartaNextcloud(tipoCarta, jsonCarta, destinatariosFormat);
    setvisible(true);
  };

  const printDoc = () => {
    guardarCartaNextcloud(tipoCarta, jsonCarta, destinatariosFormat);
  };

  const enviarCarta = async (e) => {
    console.log(pdfArray);
    let jsonPer, infoCartas, objetosCombinados;
    const promesas = [];
    try {
      for (const poliza of listaPolizas) {
        let charola = {
          RECT_idfolio: infoRow?.RECT_idfolio?.RECT_idfolio,
          PER_idpersona: infoRow?.PER_idpersona?.PER_idpersona,
          POL_idpoliza: poliza.idPoliza,
          Usuario_Login: idAnalista,
          CHCOM_entregado: false,
          CHCOM_actualizofecha: fechaActSIN,
          CHCOM_actualizousuario: nombreAnalista,
        };

        const dataCharola = await postDataToken(
          `charolacomunicacion/get_listado/`,
          charola
        );
        console.log(`Registro exitoso charolacomunicacion`, dataCharola);
      }

      for (const poliza of listaPolizas) {
        let wf = {
          RECT_idfolio: infoRow?.RECT_idfolio?.RECT_idfolio,
          POL_idpoliza: poliza.idPoliza,
          PER_idpersona: dataPolizas[0]?.PER_idpersona?.PER_idpersona,
          SIN_idsiniestro: infoRow?.SIN_idsiniestro?.SIN_idsiniestro,
          WTM_idwftareasxmodulos: IDTAREACARTADICT,
          WKT_fechawktramite: fechaActSIN,
          WKT_seejecuto: ESTADOCARTAWF,
          WKT_actualizofecha: fechaActSIN,
          WKT_actualizousuario: nombreAnalista,
        };
        const dataWF = await postDataToken(`wktramite/get_listado/`, wf);
        console.log(`Registro exitoso charolaWF`, dataWF);
      }

      if (tipoCarta === IDCARTARECHA || tipoCarta === IDCARTARESCI) {
        for (const poliza of listaPolizas) {
          for (const causa of arrayDocs) {
            const data = {
              RECT_idfolio: infoRow?.RECT_idfolio?.RECT_idfolio,
              POL_idpoliza: poliza.idPoliza,
              CXC_idcausa: causa.CXC_idcausa,
              LTXPOL_actualizofecha: fechaActSIN,
              LTXPOL_actualizousuario: nombreAnalista,
            };

            const promesa = postDataToken(
              `cartapolizatramite/get_listado/`,
              data
            );
            console.log(`Registro exitoso cartapolizatramite`, promesa);
            promesas.push(promesa);
          }
        }

        infoCartas = await Promise.all(promesas);
        console.log(infoCartas);
      } else {
        for (const poliza of listaPolizas) {
          const data = {
            RECT_idfolio: infoRow?.RECT_idfolio?.RECT_idfolio,
            POL_idpoliza: poliza.idPoliza,
            CXC_idcausa: SOLICITUDDOC,
            LTXPOL_actualizofecha: fechaActSIN,
            LTXPOL_actualizousuario: nombreAnalista,
          };

          const promesa = postDataToken(
            `cartapolizatramite/get_listado/`,
            data
          );
          console.log(`Registro exitoso cartapolizatramite`, promesa);
          promesas.push(promesa);
        }

        infoCartas = await Promise.all(promesas);
        console.log(infoCartas);
      }

      if (pdfArray.length > 0) {
        let docs = [];
        pdfArray.forEach((element, index) => {
          console.log(element);
          if (tipoCarta === IDCARTARECHA) {
            docs.push({ doc: element, docName: `Carta_rechazo_` });
          } else if (tipoCarta === IDCARTARESCI) {
            docs.push({ doc: element, docName: `Carta_rescisión_` });
          } else {
            docs.push({
              doc: element,
              docName: `Carta_solicitud_de_documentos_`,
            });
          }
        });
        console.log(docs);
        const resultURLS = await uploadCartasToNextcloud(
          infoRow?.RECT_idfolio?.RECT_numerofolio,
          docs
        );
        console.log(resultURLS.url);
        if (resultURLS.length > 0) {
          objetosCombinados = destinatariosFormat.map((destinatario, index) => {
            const resultURL = resultURLS[index];
            return {
              ...destinatario,
              ...resultURL,
            };
          });
          console.log("Objetos combinados:", objetosCombinados);
        }
      }

      if (infoCartas) {
        const resultadoCombinado = combinarArreglos(
          objetosCombinados,
          infoCartas
        );
        console.log(resultadoCombinado);
        for (const destinatarios of resultadoCombinado) {
          jsonPer = {
            PER_idpersona: destinatarios.idPersona,
            DESXLTR0_pathcarta:
              destinatarios.url.length > 0 ? destinatarios.url : "https://file",
            LTXPOL_idcartaxpoliza: destinatarios.LTXPOL_idcartaxpoliza,
            DESXLTR0_fechaentregadocarta: fechaActSIN,
            DESXLTR0_actualizofecha: fechaActSIN,
            DESXLTR0_actualizousuario: nombreAnalista,
          };
          console.log(jsonPer);
          const data4 = await postDataToken(
            `destinatarioxcarta/get_listado/`,
            jsonPer
          );
          console.log(`Registro exitoso destinatarioxcarta`, data4);

          if (data4) {
            // Redirección a la página deseada
            window.location.href = "/dictamen";
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function combinarArreglos(arreglo1, arreglo2) {
    const resultado = [];

    arreglo2.forEach((elemento2) => {
      const coincidencias = arreglo1.filter(
        (elemento1) => elemento1.polizas[0]?.id === elemento2?.POL_idpoliza
      );

      if (coincidencias.length > 0) {
        coincidencias.forEach((coincidencia) => {
          resultado.push({ ...coincidencia, ...elemento2 });
        });
      }
    });

    return resultado;
  }

  return (
    <>
      {visible && (
        <VerCarta
          array={<PDFViewer pdfArray={pdfArray} />}
          onClick1={() => setvisible(false)}
        />
      )}
      <div className="div-parent">
        <div className="div-breadcrumbs">
          <CustomSeparatorDinamic
            breadcrumbs={arrayBreadcrumbs}
            name={
              <FormattedMessage
                id="dictaGenCarta"
                defaultMessage="Generar carta"
              />
            }
          />
        </div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Link
            style={{ textDecoration: "none" }}
            to="/dictamen/detalle_folio"
            state={{ row: dataPolizas }}
          >
            <ReturnPage />
          </Link>
        </Grid>
        <div className="div-container">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <h3-title-card>No. de póliza:</h3-title-card>
            </Grid>
            <Grid item>
              <h3-subtitle-card>
                {listaPolizas.map((poliza) => poliza?.poliza).join(", ")}
              </h3-subtitle-card>
            </Grid>
          </Grid>
          {/* STEPPER  */}
          <Grid style={{ marginTop: "50px" }} xs={12}>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <h3-subtitle-card>
                        <Typography variant="caption">
                          <FormattedMessage
                            id="regisOpcio"
                            defaultMessage="Opcional"
                          />
                        </Typography>
                      </h3-subtitle-card>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>
                        <h3-subtitle-card>{label}</h3-subtitle-card>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <br></br>
              <br></br>

              {activeStep != 0 ? null : agregarPolizas()}
              {activeStep != 1 ? null : seleccionarDestinatarios()}
              {activeStep != 2 ? null : seleccionarCarta()}
              {activeStep != 3 ? null : confirmarDatosCarta()}

              {activeStep === steps.length ? (
                <React.Fragment>
                  {/*<Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                        </Typography>*/}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reiniciar</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/*<Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>*/}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {activeStep != 0 ? (
                      <ButtonVino
                        enlace="/dictamen/detalle_folio/generar_carta"
                        label={"Reiniciar"}
                      />
                    ) : null}
                    <Box sx={{ flex: "1 1 auto" }} />
                    {activeStep === steps.length - 1 ? (
                      <DialogButton
                        to={"/dictamen/detalle_folio"}
                        state={{ row: dataPolizas }}
                        estatus={disabledButton}
                        mainButton={
                          <FormattedMessage
                            id="globEnviar"
                            defaultMessage="Enviar"
                          />
                        }
                        title={
                          <FormattedMessage
                            id="dictaOpExito"
                            defaultMessage="Operación exitosa"
                          />
                        }
                        description={
                          <FormattedMessage
                            id="dictaCartEnv"
                            defaultMessage="La carta fue enviada a Comunicación con el cliente."
                          />
                        }
                        button={
                          <FormattedMessage
                            id="globAceptr"
                            defaultMessage="Aceptar"
                          />
                        }
                        onClick={enviarCarta}
                        onClick1={printDoc}
                      />
                    ) : (
                      <ButtonVino
                        estatus={disabledButton}
                        label={
                          <FormattedMessage
                            id="globSiguie"
                            defaultMessage="Siguiente"
                          />
                        }
                        onClick={handleNext}
                      />
                    )}
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default CompGenerarCarta;
