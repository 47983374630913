import React from "react";
//importacion del estilo
import "../../css/estilo.css";
import Recorrido from "../Tools/BreadCrumbs/Recorrido3";
import { useReactToPrint } from "react-to-print";
import { format } from "date-fns";

//importacion de componentes estandarizados

import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import SelectorPersona from "../Recepcion/Elements/selectsRecepcion/SelectorPersona";
import FormTramiteAsegurado from "../Recepcion/Elements/Forms/FormTramiteAsegurado";
import TablaDocumentacion from "./Elements/Tables/TableDocumentacion";
import DatePicker2 from "../Tools/DatePicker/DatePicker2";
import DialogButtons from "../Tools/Dialog/DialogButtons";
import Check2 from "../Tools/CheckBox/Check2";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { getDataToken, postDataToken, putDataToken } from "../../api/dataToken";
import { getToken } from "../../api/token";
import { BASE_API } from "../../utils/constans";
import { FormTipoTramiteSiniestro } from "./Elements/Forms/FormTipoTramiteSiniestro";
import {
  createFolderFolio,
  uploadFilesToNextcloud2,
} from "../../nextcloud/functions";
import moment from "moment";
import { formatofecha } from "../../api/getUmbrales";

import {
  TRAM_FALLECIMIENTO,
  PERR_AGENTE,
  PERR_BENEFICIARIO,
  PERR_CONTRATANTE,
  PERR_INTERNO,
  PERR_OTRO,
  PERR_ASEGURADO,
  TRAM_VENCIMIENTO,
  TRAM_BENEFICIOSEV,
  COBERTURA_FALLE_DEFAULT,
  TRAM_MANUAL,
  TRAM_PM,
  PER_FISICA,
} from "../../utils/constanstoken";

//importacion del multilenguaje

import { FormattedMessage } from "react-intl";

//importaciones para el paso de variables
import { useLocation } from "react-router-dom";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import DialogGenFolio from "./Elements/Dialog/DialogGenerarFolio";
import { Select1 } from "../Tools/Selectss/Select1";
import IconEliminar from "../Tools/Icons/IconEliminar";
import DialogIconDeleteConfirm from "../Tools/Dialog/DialogIconDeleteConfirm";
import {
  formatNumber,
  formatNumberComa,
  formatNumberDecimalComa,
} from "../../utils/functions";
import { filter } from "jszip";
import { AcuseRecepcion } from "../Dictamen/Elements/Cartas/CartasDictamen";
import { pdf } from "@react-pdf/renderer";

export function CompNuevoTramite() {
  //declaramos las siguientes variables que toman los datos que se recibieron en formulario poliza sin registro
  const location = useLocation();
  const infoRow = location.state.dataForm;

  // //Paso variables de tabla principal de recepcion para crear un tramite si se desea un registro de policy master
  const location2 = useLocation();
  const infoRow2 = location2.state.pasoVariables;
  console.log("Inforow2", infoRow2);
  //Datos del asegurado
  const [dataAse, setDataAse] = useState({
    nombre: "",
    apePat: "",
    apeMat: "",
    fechaNac: "",
    rfc: "",
    curp: "",
  });
  //Datos de las coberturas
  const [dataCober, setDataCober] = useState([]);
  //Ref para hacer referencia el formulario
  const gridTramiteRef = useRef(null);
  const [tipoTramite, setTipoTramite] = useState(0);
  const [listCoberturas, setListCoberturas] = useState([]);
  //const para el estado Acuse
  const [dataAcuse, setDataAcuse] = useState({
    dataAsegurado: {
      nombre: "",
      apePat: "",
      apeMat: "",
      fechaNac: "",
      correo: "",
      rfc: "",
      curp: "",
    },
    dataContacto: {
      nombre: "",
      apePat: "",
      apeMat: "",
      fechaNac: "",
      correo: "",
    },
    datosTramite: {
      noFolio: "",
      tipoTramite: "",
      // polizas: [
      //     {
      //         numPoliza: "NL123",
      //         coberturas: [{ nombCober: "Cobertura por fallecimiento", SA: "100000", moneda: "MXN" }]
      //     },
      polizas: [],
    },
    // documentos: [{
    //     nombre: "Poliza",
    //     original: true,
    //     copia: false,
    // }]
    documentos: [],
  });

  useEffect(() => {
    for (let elements of infoRow2) {
      for (let poliza of elements) {
        if (poliza?.PERR_idrol == PERR_ASEGURADO.id) {
          //validamos que el objeto sea la  del asegurado
          let fechaNac = moment(poliza?.PER_fechanacimiento).format(
            formatofecha
          );
          setDataAse((prevData) => ({
            ...prevData,
            nombre: poliza?.PER_nombre,
            apePat: poliza?.PER_apellidopaterno,
            apeMat: poliza?.PER_apellidomaterno,
            fechaNac: fechaNac,
            rfc: poliza?.PER_RFC,
            curp: poliza?.PER_CURP,
          }));
          setDataAcuse((prevState) => ({
            ...prevState,
            dataAsegurado: {
              ...prevState.dataAsegurado,
              nombre: poliza?.PER_nombre,
              apePat: poliza?.PER_apellidopaterno,
              apeMat: poliza?.PER_apellidomaterno,
              fechaNac: fechaNac,
              rfc: poliza?.PER_RFC,
              curp: poliza?.PER_CURP,
            },
          }));
          setIdProducto(poliza.PRO_idproductos);
        }
        if (Array.isArray(poliza)) {
          // Si el elemento es un array, es el arreglo de coberturas
          setDataCober(poliza);
          console.log("polizaaaaaaaaaaaa", poliza);
          if (poliza[0]?.tipoTram != 0) {
            setTipoTramite(parseInt(poliza[0].tipoTram));
            console.log("Tramiteeeee", parseInt(poliza[0].tipoTram));
          }
        }
      }
    }
    // let filterCobPM = infoRow2[0].filter((obj) => obj.hasOwnProperty('COB_idcobertura') && obj.COB_idcobertura);
    // console.log("filterCobPMmmmmmmmmmmmmmm", filterCobPM)
    // setListCoberturas(filterCobPM)
    let filterCobPM = infoRow2[0]
      .reduce((acc, obj) => {
        if (obj.hasOwnProperty("COB_idcobertura") && obj.COB_idcobertura) {
          // Utilizar un conjunto para garantizar coberturas únicas
          acc.set(obj.COB_idcobertura, obj);
        }
        return acc;
      }, new Map())
      .values();

    // Convertir el conjunto a un array
    filterCobPM = Array.from(filterCobPM);
    setListCoberturas(filterCobPM);
  }, []);

  useEffect(() => {
    if (tipoTramite != 0) {
      console.log("Tipo tramite", tipoTramite);
    }
  }, [tipoTramite]);
  //UseEfect para la funcionalidad de las coberturas por póliza
  const [dataPolizasCober, setDataPolizasCober] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("List coberturas", listCoberturas);
  }, [listCoberturas]);
  const getData = async () => {
    const newData = [];
    let currentPolizaObject = null;
    let coberturas = [];

    for (let elements of infoRow2) {
      for (let poliza of elements) {
        if (Array.isArray(poliza)) {
          // Si es un array, agregamos un arreglo de objetos al objeto actual
          currentPolizaObject.coberturas = poliza;
          //
        }

        if (poliza.tipoTramite == TRAM_MANUAL) {
          if (poliza?.PERR_idrol === PERR_ASEGURADO.id) {
            // Si es la póliza del asegurado, creamos un nuevo objeto
            currentPolizaObject = {
              poliza: poliza?.POL_numpoliza,
              coberturas: [],
              tipoTramite: TRAM_MANUAL,
            };
          }
        } else if (poliza.tipoTramite == TRAM_PM) {
          if (poliza?.PERR_idrol === PERR_ASEGURADO.id) {
            // Si es la póliza del asegurado, creamos un nuevo objeto
            currentPolizaObject = {
              poliza: poliza?.POL_numpoliza,
              coberturas: [],
              tipoTramite: TRAM_PM,
            };
          }
        }
        if (poliza.idCober) {
          let coberturasList = await getDataToken(
            "coberturas/get_id/" + poliza.idCober + "/"
          );
          if (
            coberturasList &&
            coberturasList.TRA_idtipotramitesiniestro
              .TRA_idtipotramitesiniestro == tipoTramite
          ) {
            poliza.tipoTram = tipoTramite;
            coberturas.push(poliza);
          }
        }
      }

      if (currentPolizaObject && coberturas.length > 0) {
        console.log("Coberturaaaaaaaas:", coberturas);
        currentPolizaObject.coberturas = coberturas;
        newData.push(currentPolizaObject);
        console.log("NEW DATAAAAAAAAAAAAA", newData);
        currentPolizaObject = null; // Reiniciamos el objeto temporal
      } else if (currentPolizaObject) {
        newData.push(currentPolizaObject);
        currentPolizaObject = null; // Reiniciamos el objeto temporal
      }
    }

    setDataPolizasCober(newData);
  };
  //Funcion para eliminar una cobertura del trámite

  const deleteCoberturaById = (coberturaId, numPoliza) => {
    setDataPolizasCober((prevState) => {
      const newData = prevState.map((poliza) => {
        // Si es la póliza del asegurado y tiene coberturas
        if (
          poliza.poliza &&
          poliza.coberturas &&
          poliza.coberturas.length > 0 &&
          poliza.poliza == numPoliza
        ) {
          // Filtrar las coberturas, eliminando la que tiene el ID específico
          poliza.coberturas = poliza.coberturas.filter(
            (cobertura) => cobertura.idCober != coberturaId
          );
        }
        return poliza;
      });
      return newData;
    });
  };

  useEffect(() => {
    console.log("Data coberturas:", dataCober);
  }, [dataCober]);
  useEffect(() => {
    console.log("Data polizas coberturas:", dataPolizasCober);
  }, [dataPolizasCober]);

  //Funciones para folios
  const [NoAleatorio, setNoAleatorio] = useState("");
  const [btnGenerarFolio, setBtnGenerarFolio] = useState(false);
  const [coberturas, setCoberturas] = useState([]);
  const [idProducto, setIdProducto] = useState(0);

  //Generar un folio al azar
  function generarId() {
    const numero = Math.floor(Math.random() * 10000000);
    const numeroFolio = ("00000000" + numero).slice(-7);

    const prefijo = "REC";
    return prefijo + numeroFolio;
  }
  useEffect(() => {
    getDataFolio();
    getDataCoberturas();
  }, []);
  const getDataFolio = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var result = await getDataToken(
      "recepcion/get_numfolio/" + generarId() + "/"
    );
    if (result === null) {
      setNoAleatorio(generarId());
    } else {
      getDataFolio();
    }
  };
  const getDataCoberturas = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var result = await getDataToken("coberturas/get_listado/");
    if (result) {
      let copiaApi = result.map((objeto) => {
        return {
          ...objeto,
          identificador: objeto.COB_idcobertura,
          texto: objeto.COB_cobertura,
        };
      });
      setCoberturas(copiaApi);
    }
  };
  /****** Código Usuario que actualizó *******/
  const [user, setUser] = useState("");
  //GET A API USER
  const getDataUser = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var result = await getDataToken(
      "login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/"
    );
    setUser(
      result?.SNYTLOG_username +
        " " +
        result?.SNYTLOG_surnamedad +
        " " +
        result?.SNYTLOG_surnamemom
    );
  };
  useEffect(() => {
    getDataUser();
  }, []);

  // Esta funcion sirve para seleccionar si el asegurado es quien realiza el trámite
  const toogleSwitch = (e) => {
    if (e.target.checked) {
      setAseguradoSi(true);
      setIdRolPer(PERR_ASEGURADO.id);
    } else {
      setAseguradoSi(false);
      setIdRolPer(0);
    }
  };
  // Esta funcion sirve para seleccionar una cobertura adicional
  const toogleSwitchCobAdic = (e) => {
    if (e.target.checked) {
      setCoberAdi(true);
    } else {
      setCoberAdi(false);
    }
  };
  //Funcion de activacion checks

  const toogleSwitch2 = (e) => {
    if (e.target.checked) {
      setDocumentacionSi(true);
    } else {
      setDocumentacionSi(false);
    }
  };

  //Hooks para ocultar y mostrar la tabla de documentacion y el formulario del asegurado
  const [aseguradoSi, setAseguradoSi] = useState(false);
  const [documentacionSi, setDocumentacionSi] = useState(false);

  //Hooks para ocultar y mostrar el select para cobertura adicional
  const [coberAdi, setCoberAdi] = useState(false);

  //Fecha actual para el registro folio
  let today = new Date();

  const [selectedDate, setSelectedDate] = useState(today);
  //Obtenemos el valor actual que el usuario seleccione
  const handleDateChange = (newData) => {
    let today2 = new Date(newData.$d);
    const formatedDate = format(today2, "yyyy-MM-dd");
    const formatedHour = format(today, "HH:mm:ss");
    let date = `${formatedDate}T${formatedHour}`;
    setSelectedDate(date);
  };

  //Impresion del folio desde el ordenador actuaiza si la casilla esta activa
  const [printChecked, setPrintChecked] = useState(false);

  //POST Mandar email cuando se seleccione la casilla correo

  const [emailChecked, setEmailChecked] = useState(false);
  const postEmail = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("authorization", "Bearer " + getToken());
    try {
      await fetch(`${BASE_API}notificador/mail/`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          EMLNT_email: "ejemplo@qait.com.mx",
          EMLNT_asunto: "Su tramite se guardo con el folio:",
          EMLNT_texto: NoAleatorio,
          EMLNT_PORT: 587,
          EMLNT_TLS: true,
          EMLNT_HOST: "mail.qait.com.mx",
          EMLNT_HOST_USER: "admin@qait.com.mx",
          EMLNT_HOST_PASSWORD: "6xxQKSn{e~gl",
        }),
      });
      console.log("Envio de correo exitoso");
    } catch (error) {
      // handleOpen(true);
      console.log(error.message);
      throw error;
    }
  };
  /******* ESTADO PARA ID ROL PERSONA********/
  const [idRolPer, setIdRolPer] = useState(0);

  /********** Código DOCUMENTACION *************/
  const [idCobertura, setIdCobertura] = useState(COBERTURA_FALLE_DEFAULT.id);
  const [dataApiDoc, setDataApiDoc] = useState([]);
  const [docsTramite, setDocsTramite] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [documentosExpTram, setDocumentosExpTram] = useState([]);
  const [postPolCobPro, setPostPolCobPro] = useState(false);

  //GET A API DOCUMENTACION
  const getDataDocumentacion = async (tipoTram) => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var resultInves = await getDataToken(
      "documentosxtiposiniestrostramite/get_listado/"
    );
    setDataApiDoc(resultInves);
    actualizaListaDocs(resultInves, tipoTram);
  };
  useEffect(() => {
    if (tipoTramite == 0) {
      //
    } else {
      getDataDocumentacion(tipoTramite);
    }
  }, [tipoTramite]);

  useEffect(() => {
    if (tipoTramite == TRAM_FALLECIMIENTO.id) {
      setAseguradoSi(false);
      // setIdRolPer(0);
    }
    if (tipoTramite != 0) {
      getData();
      console.log("TRAMITEEEEEEEEEEEEEEE", tipoTramite);
      //Filtrar las coberturas por tipo de trámite
      setDataPolizasCober((prevState) => {
        const newData = prevState.map((poliza) => {
          // Si es la póliza del asegurado y tiene coberturas
          if (poliza.coberturas && poliza.coberturas.length > 0) {
            poliza.coberturas = poliza.coberturas.filter(
              (cobertura) =>
                parseInt(cobertura.tipoTram) == parseInt(tipoTramite)
            );
          }
          return poliza;
        });
        return newData;
      });
    }
  }, [tipoTramite]);

  const actualizaListaDocs = (arr, tipoTram) => {
    //Una vez la API de documentos haya cambiado el estado dataApiDoc, esta debera filtrar
    // getData();
    console.log("Tipo tramiteeeee SINIESTROOOOOOOOOOOOOOOO", tipoTram);
    console.log("dataApiDoc", dataApiDoc);
    //la respuesta segun el tipo de trámite actual
    if (arr.length > 0) {
      // Agregar un nuevo elemento al estado sin modificar el original
      let filtro = arr.filter(function (objeto) {
        return (
          objeto.TRA_idtipotramitesiniestro.TRA_idtipotramitesiniestro ==
          tipoTram
        );
      });

      let copiaApiDoc = filtro.map((objeto) => {
        return {
          ...objeto,
          identificador: objeto.id,
          texto: objeto.DOC_iddocumento.DOC_documento,
        };
      });
      setDocsTramite(copiaApiDoc);
    }
  };
  //Limpiar la tabla de documentos al tener un cambio en el siniestro a tramitar
  const [limpiarTabla, setLimpiarTabla] = useState(false);

  React.useEffect(() => {
    handleEvento();
    if (tipoTramite === TRAM_FALLECIMIENTO.id) {
      setAseguradoSi(false);
    }
  }, [tipoTramite]);
  const handleEvento = () => {
    setLimpiarTabla(true);
  };
  const limpiarTablaCompleto = () => {
    setLimpiarTabla(false);
  };

  //******************************************************* POST DATA TOKEN ************************************************ */
  let postRecepcionEjecutado = false; // Bandera para controlar si postRecepcion se ejecutó
  let postCharolaEjecutado = false; // Bandera para controlar si postCharola se ejecutó
  let postPolCobProd = false; // Bandera para controlar si postCharola se ejecutó
  let idRecFolio = null;
  let postPersonaTramite = false; // Bandera para controlar si postPersona se ejecutó

  //metodo post PERSONAS / PÓLIZA
  const postData = async (aux, personaTramite) => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    try {
      //ejecutar lo siguiente por cada poliza recibida de la pantalla anterior
      let cont = 0;
      for (const row of infoRow2) {
        let asegurado = null;
        let aseguradoId = null;
        let contratante = null;
        let contratanteId = null;
        let agente = null;
        let agenteId = null;
        let beneficiario = null;
        let beneficiarioId = null;
        let polizaId = null;
        let personasPolizas = [];
        let polizas = [];

        for (const poliza of row) {
          // Se iteran las polizas que fueron seleccionadas, obteniendo solo el objeto que coincida con el rol asegurado.
          if (poliza?.PERR_idrol == PERR_ASEGURADO.id) {
            //validamos que el objeto sea la  del asegurado
            asegurado = poliza;
            //  registrar asegurado en SINAY
            let jsonAsegurado = {
              PER_nombre: asegurado?.PER_nombre,
              PER_apellidopaterno: asegurado?.PER_apellidopaterno,
              PER_apellidomaterno: asegurado?.PER_apellidomaterno,
              PER_CURP: asegurado?.PER_CURP,
              PER_RFC: asegurado?.PER_RFC,
              PER_fechanacimiento: asegurado?.PER_fechanacimiento,
              PER_sexo: !asegurado?.PER_sexo ? "Hombre" : asegurado?.PER_sexo,
              PERT_idtipopersona: 1,
              PERR_idrol: PERR_ASEGURADO.id,
              PER_actualizousuario: user,
              PER_actualizofecha: today,
            };
            console.log("Json asegurado", jsonAsegurado);
            let result = await postDataToken(
              "personas/get_listado/",
              jsonAsegurado
            );
            aseguradoId = result.PER_idpersona;
            //json poliza

            let fechaIsoVigencia = moment(
              asegurado.POL_polizafinvigencia,
              ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD"],
              true
            ).isValid()
              ? moment(
                  asegurado.POL_polizafinvigencia,
                  ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD"],
                  true
                ).toISOString()
              : null;
            let fechaIsoEmision = moment(
              asegurado.POL_polizafecemisión,
              ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD"],
              true
            ).isValid()
              ? moment(
                  asegurado.POL_polizafecemisión,
                  ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD"],
                  true
                ).toISOString()
              : null;
            let fechaIsoPagadaHasta = moment(
              asegurado.POL_polizapahasta,
              ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD"],
              true
            ).isValid()
              ? moment(
                  asegurado.POL_polizapahasta,
                  ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD"],
                  true
                ).toISOString()
              : null;

            let jsonPoliza2 = {
              POL_numpoliza: asegurado?.POL_numpoliza
                ? asegurado?.POL_numpoliza
                : null,
              POL_polizapadre: asegurado?.POL_polizapadre
                ? asegurado?.POL_polizapadre
                : "",
              POL_polizapahasta: fechaIsoPagadaHasta
                ? fechaIsoPagadaHasta.slice(0, 10)
                : null,
              POL_polizafoinversión: asegurado?.POL_polizafoinversión
                ? asegurado?.POL_polizafoinversión
                : "",
              POL_polizapréstamo: asegurado?.POL_polizapréstamo
                ? asegurado?.POL_polizapréstamo
                : "",
              POL_polizarematemática: asegurado?.POL_polizarematemática
                ? asegurado.POL_polizarematemática
                : "",
              POL_polizatireaseguro: asegurado?.POL_polizatireaseguro
                ? asegurado.POL_polizatireaseguro
                : false,
              POL_polizareasegurador: asegurado?.POL_polizareasegurador
                ? asegurado?.POL_polizareasegurador
                : "",
              POL_polizaprima: asegurado?.POL_polizaprima
                ? asegurado?.POL_polizaprima
                : "",
              POL_polizafinvigencia: fechaIsoVigencia
                ? fechaIsoVigencia.slice(0, 10)
                : null,
              POL_polizafecemisión: fechaIsoEmision
                ? fechaIsoEmision.slice(0, 10)
                : null,
              POL_aniosduracionpoliza: asegurado?.POL_aniosduracionpoliza
                ? asegurado?.POL_aniosduracionpoliza
                : null,
              POL_activo: true,
              POL_actualizofecha: today,
              POL_actualizousuario: user,
              MON_idmonedas: asegurado?.MON_idmonedas
                ? asegurado?.MON_idmonedas
                : null,
              EST_idestatuspoliza: asegurado?.EST_idestatuspoliza
                ? asegurado?.EST_idestatuspoliza.EST_idestatuspoliza
                : null,
              CHN_idcanales: asegurado?.CHN_idcanales
                ? asegurado?.CHN_idcanales
                : null,
              OF_idoficinas: asegurado?.OF_idoficinas
                ? asegurado?.OF_idoficinas
                : null,
              FPAG_idformasdepago: asegurado?.FPAG_idformasdepago
                ? asegurado?.FPAG_idformasdepago
                : null,
              PRO_idproductos: asegurado?.PRO_idproductos
                ? asegurado?.PRO_idproductos
                : null,
              ESTA_idestado: asegurado?.ESTA_idestado
                ? asegurado?.ESTA_idestado
                : null,
            };
            console.log("JsonPoliza: ", jsonPoliza2);
            let resultPol = await postDataToken(
              "polizas/get_listado/",
              jsonPoliza2
            );
            polizaId = resultPol.POL_idpoliza;
            polizas.push({
              idPoliza: polizaId,
              numPoliza: resultPol.POL_numpoliza,
              sumaAsegurada: asegurado.PCP_coberturasumaasegurada
                ? asegurado.PCP_coberturasumaasegurada
                : 0,
            });
            personasPolizas.push({
              personaId: aseguradoId,
              polizaId: polizaId,
            });
          } else if (poliza?.PERR_idrol == PERR_CONTRATANTE.id) {
            //validamos que el objeto sea la  del contratante
            contratante = poliza;
            // registrar contratante en SINAY
            console.log("Hacer post contratante", contratante);
            let jsonContratante = {
              PER_nombre: contratante.PER_nombre,
              PER_apellidopaterno: contratante?.PER_apellidopaterno,
              PER_apellidomaterno: contratante?.PER_apellidomaterno,
              PERR_idrol: PERR_CONTRATANTE.id,
              PERT_idtipopersona: 1,
              PER_actualizousuario: user,
              PER_actualizofecha: today,
            };
            let result = await postDataToken(
              "personas/get_listado/",
              jsonContratante
            );
            contratanteId = result.PER_idpersona;
            personasPolizas.push({
              personaId: contratanteId,
              polizaId: polizaId,
            });
          } else if (poliza?.PERR_idrol == PERR_AGENTE.id) {
            //validamos que el objeto sea la  del agente
            agente = poliza;
            // registrar agente en SINAY
            console.log("Hacer post agente", agente);
            let jsonAgente = {
              PER_nombre: agente.PER_nombre,
              PER_apellidopaterno: agente?.PER_apellidopaterno,
              PER_apellidomaterno: agente?.PER_apellidomaterno,
              PERR_idrol: PERR_AGENTE.id,
              PERT_idtipopersona: 1,
              PER_actualizousuario: user,
              PER_actualizofecha: today,
            };
            let result = await postDataToken(
              "personas/get_listado/",
              jsonAgente
            );
            agenteId = result.PER_idpersona;
            personasPolizas.push({ personaId: agenteId, polizaId: polizaId });
          } else if (
            poliza?.PERR_idrol == PERR_BENEFICIARIO.id &&
            poliza?.PER_nombre !== null
          ) {
            //validamos que el objeto sea la  del beneficiario
            beneficiario = poliza;
            //registrar agente en SINAY
            console.log("Hacer post beneficiario", beneficiario);
            let jsonBeneficiario = {
              PER_nombre: beneficiario.PER_nombre,
              PER_apellidopaterno: beneficiario?.PER_apellidopaterno,
              PER_apellidomaterno: beneficiario?.PER_apellidomaterno,
              PER_fechanacimiento: beneficiario?.PER_fechanacimiento,
              PERR_idrol: PERR_BENEFICIARIO.id,
              PERT_idtipopersona: 1,
              PER_actualizousuario: user,
              PER_actualizofecha: today,
            };
            let result = await postDataToken(
              "personas/get_listado/",
              jsonBeneficiario
            );
            beneficiarioId = result.PER_idpersona;
            personasPolizas.push({
              personaId: beneficiarioId,
              polizaId: polizaId,
              porcentaje: beneficiario?.PXP_porcentajebeneficiario,
            });
          }
        }
        postPuentePerXPol(
          personasPolizas,
          polizas,
          polizaId,
          aseguradoId,
          aux,
          personaTramite,
          cont
        );

        console.log("Personas poliza:", personasPolizas);
        console.log("Polizas:", polizas);
        cont = cont + 1;
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };
  async function postPuentePerXPol(
    personasPolizas,
    polizas,
    polizaId,
    aseguradoId,
    aux,
    personaTramite,
    cont
  ) {
    try {
      for (const persona of personasPolizas) {
        let auxJsonBody = {
          PER_idpersona: persona.personaId,
          POL_idpoliza: polizaId,
          PXP_actualizousuario: user,
          PXP_porcentajebeneficiario: !persona?.porcentaje
            ? 0.0
            : parseFloat(persona?.porcentaje),
        };
        await postDataToken("personasxpoliza/get_listado/", auxJsonBody);
      }
      if (!postPersonaTramite) {
        let result1 = await postDataToken(
          "personas/get_listado/",
          personaTramite
        );
        let personaTramiteId = result1.PER_idpersona;
        let auxJsonBody2 = {
          PER_idpersona: personaTramiteId,
          POL_idpoliza: polizaId,
          PXP_actualizousuario: user,
          PXP_iscontacto: true,
        };
        let result2 = await postDataToken(
          "personasxpoliza/get_listado/",
          auxJsonBody2
        );
        if (result2) {
          postPersonaTramite = true;
          let persona = await getDataToken(
            "personas/get_id/" + result2.PER_idpersona + "/"
          );
          setDataAcuse((prevState) => ({
            ...prevState,
            dataContacto: {
              ...prevState.dataContacto,
              nombre: persona?.PER_nombre,
              apePat: persona?.PER_apellidopaterno,
              apeMat: persona?.PER_apellidomaterno,
              correo: persona?.PER_correo,
            },
          }));

          console.log("Se registró persona que realizó el trámite", result2);
        }
      }

      //Aqui el código que sigue despues de que se realizó el post a la tabla puente (detalle)
      postRecepcion(aseguradoId, polizas, polizaId, aux, cont);
      //AQui si el asegurado está asiendo el trámite
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
  async function postRecepcion(aseguradoId, polizas, polizaId, aux, cont) {
    try {
      if (!postRecepcionEjecutado) {
        let auxJsonBody = {
          RECT_idusuario: NoAleatorio, //Aqui va el id del usuario en sesion (poner uno random mientras) SUSTITUIR
          RECT_fechaaviso: today,
          RECT_fecharecepcion: today,
          RECT_numerofolio: NoAleatorio,
          RECT_entregado: true,
          RECT_fechaentregado: today,
          RECT_activo: true,
          RECT_actualizofecha: today,
          RECT_actualizousuario: user,
          SINC_idcomplejidadsiniestro: tipoTramite, // duda (ID ICONOS) *****************************
          TEST_idestatustramite: 2, // duda cambiar a registro (crear constante)
        };
        console.log("Recepcion json", auxJsonBody);
        const result = await postDataToken(
          "recepcion/get_listado/",
          auxJsonBody
        );
        //Aqui el código que sigue despues de que se realizó el post a la tabla puente (detalle)
        idRecFolio = result.RECT_idfolio;
        console.log("idRecepcion", idRecFolio);
        postRecepcionEjecutado = true; // Cambiar la bandera a true para que solo se ejecute una vez.
      }
      postSiniestros(aseguradoId, idRecFolio, polizas, polizaId, aux, cont);
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
  async function postSiniestros(
    aseguradoId,
    idRecFolio,
    polizas,
    polizaId,
    aux,
    cont
  ) {
    try {
      let result;
      for (const poliza of polizas) {
        let auxJsonBody = {
          RECT_idfolio: idRecFolio,
          SIN_fechasiniestro: selectedDate,
          SIN_actualizousuario: user,
          SEP_codpostal: null,
          SINC_idcomplejidadsiniestro: 1, // Duda sobre la API
          CSIN_idclasificacionsiniestros: null, // Duda sobre la API
          TEST_idestatustramite: 2,
        };

        result = await postDataToken("siniestros/get_listado/", auxJsonBody);
        // Haz algo con el resultado, si es necesario
      }
      console.log("Siniestros", result);
      if (result)
        //Aqui el código que sigue despues de que se realizó el post a la tabla siniestros
        postPolizaXTramites(aseguradoId, idRecFolio, polizas, polizaId, cont);
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
  async function postPolizaXTramites(
    aseguradoId,
    idRecFolio,
    polizas,
    polizaId,
    cont
  ) {
    try {
      let result;
      for (const poliza of polizas) {
        let auxJsonBody = {
          RECT_idfolio: idRecFolio,
          POL_idpoliza: polizaId,
          PXT_actualizousuario: user,
          EST_idestatuspolizatramite: 1, //Este campo esta en duda
        };
        result = await postDataToken(
          "polizaportramites/get_listado/",
          auxJsonBody
        );
      }

      console.log("polxtram", result);
      //Aqui el código que sigue despues de que se realizó el post a la tabla puente (detalle)
      if (result) {
        postPolizaCoberturasXTProductos(
          aseguradoId,
          idRecFolio,
          polizas,
          polizaId,
          cont
        );
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
  async function postPolizaCoberturasXTProductos(
    aseguradoId,
    idRecFolio,
    polizas,
    polizaId,
    cont
  ) {
    try {
      if (cont === 0) {
        postCharolaRegistro(aseguradoId, idRecFolio);
      }
      for (let poliza of polizas) {
        let findPol = dataPolizasCober.find(
          (obj) => obj.poliza == poliza.numPoliza
        );
        console.log("Encontreeeeeeeeeeee:", findPol);
        for (let cobertura of findPol.coberturas) {
          let auxJsonBody = {
            RECT_idfolio: idRecFolio,
            POL_idpoliza: poliza.idPoliza,
            PCP_actualizousuario: user,
            COB_idcobertura: cobertura.id, //Este campo esta en duda
            PCP_coberturasumaasegurada: formatNumber(cobertura.SA),
            MON_idmonedacobertura: cobertura.idMoneda,
          };
          console.log("PCPP:", auxJsonBody);
          let result = await postDataToken(
            "polizascoberturasporproductos/get_listado/",
            auxJsonBody
          );
        }
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
  async function postCharolaRegistro(aseguradoId, idRecFolio) {
    try {
      let auxJsonBody = {
        RECT_idfolio: idRecFolio,
        PER_idpersona: aseguradoId,
        CHREG_fechaentregado: today,
        CHREG_actualizofecha: today,
        CHREG_actualizousuario: user,
        CHREG_entregado: false,
        CHREG_activo: true,
        CHREG_tiemporespuesta: 30,
      };
      let resp = await postDataToken(
        "charolaregistro/get_listado/",
        auxJsonBody
      );
      console.log("charolareg", resp);
      //Aqui el código que sigue despues de que se realizó el post a la tabla puente (detalle)
      let resp2 = await crearCarpetaFolio(idRecFolio, NoAleatorio);

      setMostrarDialog(true);

      postCharolaEjecutado = true; // Cambiar la bandera a true para que solo se ejecute una sola vez.
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
  //Esta función permite crear una carpeta dentro de nextcloud
  const crearCarpetaFolio = async (idRecFolio, numAleatorio) => {
    if (numAleatorio !== "") {
      var response = await createFolderFolio(numAleatorio); //Se llama a la función createFolderFolio(folderFolio).
      if (response) {
        //Si la respuesta es true, manda mensaje se exito
        console.log("Carpeta " + numAleatorio + " creada exitosamente");
        if (documentos.length > 0) {
          try {
            let respuestaDocs = await uploadFilesToNextcloud2(
              numAleatorio,
              documentos
            );
            let docs = [];
            for (let index = 0; index < documentosExpTram.length; index++) {
              const element = documentosExpTram[index];
              let auxJsonBody = {
                RECT_idfolio: idRecFolio,
                EXPT_original: element.original,
                EXPT_copia: element.copia,
                EXPT_notas: element.notas,
                EXPT_nombrearchivo: respuestaDocs[index]
                  ? respuestaDocs[index]?.filename
                  : "",
                // EXPT_rutaarchivo: respuestaDocs[index] ? respuestaDocs[index]?.url : "",
                EXPT_rutaarchivo: "",
                //EXPT_fechaentregadocumento: today,
                EXPT_activo: true,
                EXPT_actualizofecha: today,
                EXPT_actualizousuario: user,
                TRA_idtipotramitesiniestro: tipoTramite,
              };
              console.log("JSON exptramite", auxJsonBody);
              let nombreArchivo = await getDataToken(
                "documentos/get_id/" + element.tipoDoc + "/"
              );
              docs.push({
                nombre: nombreArchivo ? nombreArchivo.DOC_documento : "",
                original: element.original,
                copia: element.copia,
              });
              const result = await postDataToken(
                "expedientetramite/get_listado/",
                auxJsonBody
              );

              setBtnGenerarFolio(false);
            }
            setDataAcuse((prevState) => ({
              ...prevState,
              documentos: docs,
            }));
          } catch (e) {
            console.log("Ocurrió un error al crear expediente");
            return null;
          }
        }
        let result3 = await getDataToken(
          "tipostramitesiniestro/get_id/" + tipoTramite + "/"
        );
        console.log("resp 3 consulta tramite", result3);
        if (result3) {
          setDataAcuse((prevState) => ({
            ...prevState,
            datosTramite: {
              ...prevState.datosTramite,
              noFolio: numAleatorio,
              tipoTramite: result3?.TRA_destipotramitesiniestro,
              polizas: dataPolizasCober,
            },
          }));
        }
      } else {
        //Si no, manda mensaje informando el estado de la operación. Por ejemplo
        console.log("Ocurrió un error al crear la carpeta");
        throw new Error(
          "Ocurrió un error al crear la carpeta",
          response.status
        );
      }
    }
  };

  const [mostrarDialogo, setMostrarDialog] = useState(false);
  const [acuseChecked, setAcuseChecked] = useState(false);
  /********************************* AQUI TERMINAN LOS POST A LAS APIS ********************************************** */
  /**
   * ESTA FUNCIÓN SIRVE PARA REDIRECCIONAR A OTRA PÁGINA
   */
  async function redireccionar(dataAcuse) {
    setMostrarDialog(false);
    if (acuseChecked) {
      let acuse = AcuseRecepcion(dataAcuse, false);

      var blob = await pdf(acuse).toBlob();
      const pdfUrl = window.URL.createObjectURL(blob);
      var printWindow = window.open(pdfUrl, "_blank");
      printWindow.print();
      URL.revokeObjectURL(pdfUrl);
    }
    window.location.href = "/recepcion";
  }
  //***FUNCION PARA AGREGAR COBERTURAS - FLUJO PM  */
  const [dataPM, setDataPM] = useState([]);

  const handleChangeCober = async (idCob) => {
    let filterCobPM = infoRow2[0].find(
      (obj) =>
        obj.hasOwnProperty("COB_idcobertura") && obj.COB_idcobertura == idCob
    );
    if (filterCobPM) {
      console.log("FILTER COBBBBBBBBBBBBBB", filterCobPM);

      // Ver si existe una coincidencia con la cobertura elegida dentro de las coberturas a reclamar
      let cont = 0;
      for (let poliza of dataPolizasCober) {
        if (poliza.tipoTramite === TRAM_PM) {
          let dataCober = await getDataToken(
            "coberturas/get_id/" + filterCobPM.COB_idcobertura + "/"
          );
          let dataMoneda = await getDataToken(
            "monedas/get_id/" + filterCobPM.COB_idmoneda + "/"
          );

          if (dataCober) {
            let obj = {
              id: cont + 1,
              idCober: dataCober.COB_idcobertura,
              nomCober: dataCober.COB_cobertura,
              SA: filterCobPM.COB_sumaasegurada,
              idMoneda: filterCobPM.COB_idmoneda,
              nomMoneda: dataMoneda.MON_abreviatura,
              tipoTram: filterCobPM.tipoTramite,
              obligatoria: dataCober.COB_obligatorio ? true : false,
            };
            if (poliza.coberturas.length <= 0) {
              console.log("Estoy aqui");
              setDataPolizasCober((prevPolizas) => {
                const nuevaListaPolizas = [...prevPolizas];
                // Utilizar el operador de propagación para agregar la nueva cobertura al arreglo específico
                poliza.coberturas = [obj];
                // Actualizar la lista de polizas con la poliza modificada
                nuevaListaPolizas[cont] = poliza;
                return nuevaListaPolizas;
              });
            } else {
              let coberturas = [...poliza.coberturas]; // Hacer una copia de las coberturas existentes
              let coberturaEncontrada = false;

              for (let cobertura of coberturas) {
                if (cobertura.idCober == idCob) {
                  coberturaEncontrada = true;
                  break;
                }
              }

              if (!coberturaEncontrada) {
                setDataPolizasCober((prevPolizas) => {
                  const nuevaListaPolizas = [...prevPolizas];
                  // Utilizar el operador de propagación para agregar la nueva cobertura al arreglo específico
                  coberturas.push(obj);
                  poliza.coberturas = coberturas;
                  // Actualizar la lista de polizas con la poliza modificada
                  nuevaListaPolizas[cont] = poliza;
                  return nuevaListaPolizas;
                });
              }
            }
          }
        }
        cont = cont + 1;
      }
      let findCob = dataPolizasCober.filter((obj) => obj.coberturas == idCob); //Ya esta agregada esa cobertura
      if (!findCob) {
        //no encontro, agregarla
      }
    }
    // let findCob = dataPolizasCober.find(obj => obj.idCober == idCob) //Ya esta agregada esa cobertura
    // if (!findCob) {
    //   let findCobPM = dataPolizasCober.find(obj => obj.idCober == idCob) //Ya esta agregada esa cobertura

    //   setDataPolizasCober([...dataPolizasCober, {
    //       poliza: "",
    //       coberturas: [{SA : "100,000",
    //       id : 2,
    //       idCober : "12",
    //       idMoneda : 6,
    //       nomCober :  "Protección Adicional por Fallecimiento",
    //       nomMoneda : "BSD",
    //       tipoTram : "1",}]
    //     }])
    // }
  };

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={
            <FormattedMessage id="globRecepc" defaultMessage="Recepción" />
          }
          enlace2="/recepcion"
          name3={
            <FormattedMessage id="submenuNue" defaultMessage="Nuevo trámite" />
          }
          enlace3="/nuevoTramite"
        />
      </div>
      {/**Insercion del boton regresar */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/recepcion" />
        {/**Aqui termina la linea de codigo del boton regresar con su enlace */}
      </Grid>
      <div className="div-container">
        <form
          onSubmit={async (e) => {
            try {
              const {
                //Datos asegurado
                txtCorreo,
                txtTelFijo,
                txtTelCel,
                SEP_idsepomex,
                txtCalle,
                txtExt,
                txtInt,

                //Datos agente
                txtNombre,
                txtApePat,
                txtApeMat,
                txtClaveAgente,
                txtDA,

                //Datos otro
                txtParentesco,

                selectPersona,
              } = e.target.elements;
              e.preventDefault();
              setBtnGenerarFolio(true);
              let aux = {
                //   //Aqui inician los valores del formulario actual
                SEP_idsepomex: SEP_idsepomex
                  ? parseInt(SEP_idsepomex.value)
                  : null,
                PERR_idrol: idRolPer,
                //   SIN_fechasiniestro: selectedDate,
              };

              // if (idCobertura == 0) { // Validar si ya se eligió una cobertura
              //   if (coberAdi) {
              //     console.log("id cober", idCobertura)
              //     document.getElementById("selectCoberturaAdi").focus();
              //     console.log(document.getElementById("selectCoberturaAdi"))
              //     setBtnGenerarFolio(false);

              //   } else {
              //     console.log("id cober", idCobertura)
              //     console.log(document.getElementById("secTramite"))
              //     document.getElementById("gridTramiteRef").focus();
              //     setBtnGenerarFolio(false);
              //   }
              // }
              // Verificar si todos los objetos tienen la propiedad "coberturas" vacía
              const validateCoberturas = dataPolizasCober.some(
                (objeto) => !objeto.coberturas || objeto.coberturas.length === 0
              );
              if (validateCoberturas) {
                // Todos los objetos tienen vacío su objeto de coberturas
                document.getElementById("inputCoberturas").focus();
                setBtnGenerarFolio(false);
                throw new Error("Faltan coberturas por reclamar");
              }
              if (idRolPer === 0) {
                document.getElementById("selectPersona").focus();
                setBtnGenerarFolio(false);
                throw new Error("Seleccione una persona");
              }
              if (!aseguradoSi) {
                if (selectPersona.value == 0) {
                  setBtnGenerarFolio(false);
                  throw new Error("Seleccione una persona");
                }
              }
              let personaTramite = {};

              let datosAsegurado = null;

              for (const row of infoRow2) {
                // Se iteran las polizas que fueron seleccionadas, obteniendo solo el objeto que coincida con el rol asegurado.
                for (const poliza of row) {
                  // Se iteran las polizas que fueron seleccionadas, obteniendo solo el objeto que coincida con el rol asegurado.
                  if (poliza?.PERR_idrol == PERR_ASEGURADO.id) {
                    datosAsegurado = poliza;
                    break;
                  }
                }
              }

              switch (idRolPer) {
                case PERR_ASEGURADO.id:
                  personaTramite = {
                    PER_nombre: datosAsegurado.PER_nombre,
                    PER_apellidopaterno: datosAsegurado?.PER_apellidopaterno,
                    PER_apellidomaterno: datosAsegurado?.PER_apellidomaterno,
                    PER_CURP: datosAsegurado?.PER_CURP,
                    PER_RFC: datosAsegurado?.PER_RFC,
                    PER_fechanacimiento: datosAsegurado?.PER_fechanacimiento,
                    PER_sexo: datosAsegurado?.PER_sexo,
                    PER_correo: txtCorreo ? txtCorreo.value : "",
                    SEP_idsepomex: SEP_idsepomex ? SEP_idsepomex.value : "",
                    PER_calle: txtCalle ? txtCalle.value : "",
                    PERR_idrol: idRolPer,
                    PER_numeroexterior: txtExt ? txtExt.value : "",
                    PER_numerointerior: txtInt ? txtInt.value : "",
                    PER_telefono: txtTelFijo ? txtTelFijo.value : "",
                    PER_celular: txtTelCel ? txtTelCel.value : "",
                    PERT_idtipopersona: 1,
                    PAR_idparentesco: txtParentesco
                      ? parseInt(txtParentesco.value)
                      : "",
                    PER_actualizousuario: user,
                    PER_actualizofecha: today,
                    PER_correo: txtCorreo,
                  };
                  break;
                case PERR_CONTRATANTE.id:
                  personaTramite = {
                    PER_nombre: txtNombre ? txtNombre.value : "",
                    PER_apellidopaterno: txtApePat ? txtApePat.value : "",
                    PER_apellidomaterno: txtApeMat ? txtApeMat.value : "",
                    PER_correo: txtCorreo ? txtCorreo.value : "",
                    SEP_idsepomex: SEP_idsepomex ? SEP_idsepomex.value : "",
                    PER_calle: txtCalle ? txtCalle.value : "",
                    PERR_idrol: idRolPer,
                    PER_numeroexterior: txtExt ? txtExt.value : "",
                    PER_numerointerior: txtInt ? txtInt.value : "",
                    PER_telefono: txtTelFijo ? txtTelFijo.value : "",
                    PER_celular: txtTelCel ? txtTelCel.value : "",
                    PERT_idtipopersona: 1,
                    PER_actualizousuario: user,
                    PER_actualizofecha: today,
                  };
                  break;
                case PERR_BENEFICIARIO.id:
                  personaTramite = {
                    PER_nombre: txtNombre ? txtNombre.value : "",
                    PER_apellidopaterno: txtApePat ? txtApePat.value : "",
                    PER_apellidomaterno: txtApeMat ? txtApeMat.value : "",
                    PER_correo: txtCorreo ? txtCorreo.value : "",
                    SEP_idsepomex: SEP_idsepomex ? SEP_idsepomex.value : "",
                    PER_calle: txtCalle ? txtCalle.value : "",
                    PERR_idrol: idRolPer,
                    PER_numeroexterior: txtExt ? txtExt.value : "",
                    PER_numerointerior: txtInt ? txtInt.value : "",
                    PER_telefono: txtTelFijo ? txtTelFijo.value : "",
                    PER_celular: txtTelCel ? txtTelCel.value : "",
                    PERT_idtipopersona: 1,
                    PAR_idparentesco: txtParentesco
                      ? parseInt(txtParentesco.value)
                      : "",
                    PER_actualizousuario: user,
                    PER_actualizofecha: today,
                  };
                  break;
                case PERR_AGENTE.id:
                  personaTramite = {
                    PER_nombre: txtNombre.value,
                    PER_apellidopaterno: txtApePat.value,
                    PER_apellidomaterno: txtApeMat.value,
                    PER_correo: txtCorreo ? txtCorreo.value : "",
                    SEP_idsepomex: SEP_idsepomex ? SEP_idsepomex.value : "",
                    PER_calle: txtCalle ? txtCalle.value : "",
                    PERR_idrol: idRolPer,
                    PER_numeroexterior: txtExt ? txtExt.value : "",
                    PER_numerointerior: txtInt ? txtInt.value : "",
                    PER_telefono: txtTelFijo ? txtTelFijo.value : "",
                    PER_celular: txtTelCel ? txtTelCel.value : "",
                    PER_clave: txtClaveAgente ? txtClaveAgente.value : "",
                    PER_direcciondeagencia: txtDA ? txtDA.value : "",
                    PERT_idtipopersona: 1,
                    PER_actualizousuario: user,
                    PER_actualizofecha: today,
                  };
                  break;
                case PERR_OTRO.id:
                  personaTramite = {
                    PER_nombre: txtNombre ? txtNombre.value : "",
                    PER_apellidopaterno: txtApePat ? txtApePat.value : "",
                    PER_apellidomaterno: txtApeMat ? txtApeMat.value : "",
                    PER_correo: txtCorreo ? txtCorreo.value : "",
                    SEP_idsepomex: SEP_idsepomex ? SEP_idsepomex.value : "",
                    PER_calle: txtCalle ? txtCalle.value : "",
                    PERR_idrol: idRolPer,
                    PER_numeroexterior: txtExt ? txtExt.value : "",
                    PER_numerointerior: txtInt ? txtInt.value : "",
                    PER_telefono: txtTelFijo ? txtTelFijo.value : "",
                    PER_celular: txtTelCel ? txtTelCel.value : "",
                    PERT_idtipopersona: 1,
                    PAR_idparentesco: txtParentesco
                      ? parseInt(txtParentesco.value)
                      : "",
                    PER_actualizousuario: user,
                    PER_actualizofecha: today,
                  };
                  break;
                case PERR_INTERNO.id:
                  personaTramite = {
                    PER_nombre: txtNombre ? txtNombre.value : "",
                    PER_apellidopaterno: txtApePat ? txtApePat.value : "",
                    PER_apellidomaterno: txtApeMat ? txtApeMat.value : "",
                    PERR_idrol: idRolPer,
                    PERT_idtipopersona: PER_FISICA,
                    PER_actualizousuario: user,
                    PER_actualizofecha: today,
                    PER_correo: txtCorreo ? txtCorreo.value : "",
                  };
                  break;
                default:
                  throw new Error("No se seleccionó persona");
              }
              postData(aux, personaTramite);
            } catch (e) {
              setBtnGenerarFolio(false);
              console.log(e);
            }
          }}
        >
          <Grid item sx={12}>
            <h3-header-card>
              <FormattedMessage
                id="recepTipAt"
                defaultMessage="Tipo de siniestro a tramitar"
              />
            </h3-header-card>
          </Grid>
          {/*formulario de siniestro a tramitar*/}
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-start"
            style={{ margin: "32px 0px 52px" }}
          >
            <input //Input auxiliar para hacer focus
              type="text"
              id="gridTramiteRef"
              ref={gridTramiteRef}
              style={{ position: "absolute", left: "-9999px", opacity: "0" }}
            />
            <FormTipoTramiteSiniestro
              listaCoberturas={listCoberturas}
              dataPM={(e) => setDataPM(e)}
              numPoliza={infoRow2[0][0].POL_numpoliza}
              tipoTramitePM={infoRow2[0][0].tipoTramite}
              tipoSiniestro={(e) => setTipoTramite(e)}
              tipoTramite={tipoTramite}
              idCobertura={(e) => handleChangeCober(e)}
              idProducto={idProducto}
              coberAdicional={coberAdi}
              coberAdicChange={() => setCoberAdi(false)}
            />
          </Grid>
          {/* {tipoTramite == TRAM_BENEFICIOSEV.id || tipoTramite == TRAM_FALLECIMIENTO.id ?
            <><Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"

            >
              <Grid item xs={"auto"} >
                <h3-subtitle-card>
                  <FormattedMessage
                    id="cobertAdicional"
                    defaultMessage="¿Reclamar una cobertura adicional?"
                  />
                </h3-subtitle-card>
              </Grid>
              <Grid item xs={"auto"} style={{ marginLeft: '20px' }}>
                <Check2
                  id="secTramite"
                  option1={<FormattedMessage id="globNo" defaultMessage="No" />}
                  option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
                  checked={coberAdi}
                  //disabled={tipoTramite === TRAM_FALLECIMIENTO ? true : false}
                  onChange={toogleSwitchCobAdic}

                />
              </Grid>
            </Grid>
              <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid xs={4}>
                  {!coberAdi ? null : <Select1 name="selectCoberturaAdi" id="selectCoberturaAdi" array={coberturas} onChange={(e) => setIdCobertura(e.target.value)} />}
                  <br></br>
                </Grid>
              </Grid></> : null
          } */}

          {/*informacion de datos del asegurado */}
          <Grid
            sx={12}
            container
            style={{ marginTop: "32px", marginBottom: "32px" }}
          >
            <h3-header-card>
              <FormattedMessage
                id="datosDaAse"
                defaultMessage="Datos del asegurado"
              />
            </h3-header-card>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={3}>
              <h3-title-card style={{ marginBottom: "20px" }}>
                <FormattedMessage
                  id="globNomCom"
                  defaultMessage="Nombre completo"
                />
              </h3-title-card>
              <h3-subtitle-card>{`${dataAse.nombre} ${dataAse.apePat} ${dataAse.apeMat}`}</h3-subtitle-card>
            </Grid>
            <Grid item xs={3}>
              <h3-title-card style={{ marginBottom: "20px" }}>
                <FormattedMessage
                  id="globFechNa"
                  defaultMessage="Fecha de nacimiento"
                />
              </h3-title-card>
              <h3-subtitle-card>{`${dataAse.fechaNac}`}</h3-subtitle-card>
            </Grid>
            <Grid item xs={3}>
              <h3-title-card style={{ marginBottom: "20px" }}>
                <FormattedMessage id="globRfcp" defaultMessage="RFC" />
              </h3-title-card>
              <h3-subtitle-card>{`${dataAse.rfc}`}</h3-subtitle-card>
            </Grid>
            <Grid item xs={3}>
              <h3-title-card style={{ marginBottom: "20px" }}>
                <FormattedMessage id="globCurp" defaultMessage="CURP" />
              </h3-title-card>
              <h3-subtitle-card>{`${dataAse.curp}`}</h3-subtitle-card>
            </Grid>
          </Grid>

          <hr></hr>
          {/*Datos de las coberturas */}
          <Grid xs={12} container style={{ margin: "20px 0px 32px" }}>
            <h3-header-card>
              <FormattedMessage
                id="recepDatCob"
                defaultMessage="Coberturas a reclamar"
              />
            </h3-header-card>
          </Grid>
          <input //Input auxiliar para hacer focus
            type="text"
            id="inputCoberturas"
            style={{ position: "absolute", left: "-9999px", opacity: "0" }}
          />
          {/* INFORMACION DE LAS COBERTURAS POR PÓLIZA*/}
          <Grid
            container
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "20px" }}
            spacing={3}
          >
            {dataPolizasCober.map((element, index) => {
              const content = []; //Se crea un arreglo para alamecenar la información de lada cobertura por cada póliza
              content.push(
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={4}>
                    <h3-title-card>Número de póliza</h3-title-card>
                  </Grid>
                  <Grid item xs={4}>
                    <h3-subtitle-card>{element.poliza}</h3-subtitle-card>
                  </Grid>
                  {element.coberturas.length === 0 ? null : (
                    <>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={3}>
                        <h3-title-card>
                          <FormattedMessage
                            id="cobertura"
                            defaultMessage="Cobertura"
                          />
                        </h3-title-card>
                      </Grid>
                      <Grid item xs={3}>
                        <h3-title-card>
                          <FormattedMessage
                            id="sumaAseg"
                            defaultMessage="Suma asegurada"
                          />
                        </h3-title-card>
                      </Grid>
                      <Grid item xs={3}>
                        <h3-title-card>
                          <FormattedMessage
                            id="moneda"
                            defaultMessage="Moneda"
                          />
                        </h3-title-card>
                      </Grid>
                    </>
                  )}
                  {element.coberturas.length === 1 ||
                  element.coberturas.length === 0 ? (
                    <Grid item xs={3}></Grid>
                  ) : (
                    <Grid item xs={1}>
                      <h3-title-card>
                        <FormattedMessage
                          id="eliminar"
                          defaultMessage="Eliminar"
                        />
                      </h3-title-card>
                    </Grid>
                  )}
                </>
              );
              if (element?.coberturas) {
                for (let obj of element.coberturas) {
                  content.push(
                    <>
                      <Grid item xs={3}>
                        <h3-subtitle-card>{obj?.nomCober}</h3-subtitle-card>
                      </Grid>
                      <Grid item xs={3}>
                        <h3-subtitle-card>
                          {formatNumberDecimalComa(obj?.SA)}
                        </h3-subtitle-card>
                      </Grid>
                      <Grid item xs={3}>
                        <h3-subtitle-card>{obj?.nomMoneda}</h3-subtitle-card>
                      </Grid>
                      {/* {element.coberturas.length === 1   || obj?.obligatoria ? <Grid item xs={3}></Grid> : */}
                      <Grid item xs={3}>
                        {/* <IconEliminar tooltipTitle="Eliminar cobertura" onClick={(e) => deleteCoberAReclamar(e, index)} /> */}
                        <DialogIconDeleteConfirm
                          title={"Eliminar cobertura"}
                          tooltipTitle={"Eliminar cobertura"}
                          description={`¿Estás seguro de eliminar la cobertura "${obj?.nomCober}" del trámite?`}
                          onClickAccept={(e) =>
                            deleteCoberturaById(obj.idCober, element.poliza)
                          }
                        />
                      </Grid>
                      {/*  } */}
                    </>
                  );
                }
              }
              content.push(<Grid item xs={12}></Grid>);
              return content;
            })}
          </Grid>
          <hr></hr>
          {/*Datos del siniestro */}
          <Grid xs={12} container style={{ margin: "20px 0px 32px" }}>
            <h3-header-card>
              {tipoTramite === TRAM_FALLECIMIENTO.id ||
              tipoTramite === TRAM_BENEFICIOSEV.id ? (
                <FormattedMessage
                  id="recepDatSi"
                  defaultMessage="Datos del siniestro"
                />
              ) : (
                <FormattedMessage
                  id="recepDatSi"
                  defaultMessage="Datos de recepción"
                />
              )}
            </h3-header-card>
          </Grid>
          {/* campos correo y telefonos*/}
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <h3-title-card>
              {tipoTramite === TRAM_FALLECIMIENTO.id ||
              tipoTramite === TRAM_BENEFICIOSEV.id ? (
                <FormattedMessage
                  id="regisFeSin"
                  defaultMessage="Fecha del siniestro"
                />
              ) : (
                <FormattedMessage
                  id="regisFeSin"
                  defaultMessage="Fecha de recepción"
                />
              )}
            </h3-title-card>
          </Grid>
          <Grid xs={4} style={{ marginBottom: "20px" }}>
            <DatePicker2
              id="SIN_fechasiniestro"
              name="SIN_fechasiniestro"
              maxDate={today}
              onDataChange={handleDateChange}
            />
          </Grid>

          <hr></hr>
          {/* Encabezado de datos contacto*/}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ margin: "20px 0px 0px" }}
          >
            <Grid item xs={12}>
              <h3-header-card>
                <FormattedMessage
                  id="recepDatCo"
                  defaultMessage="Datos de contacto"
                />
              </h3-header-card>
            </Grid>
            {/*radio de datos de contacto */}
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={"auto"} style={{ marginTop: "20px" }}>
                <h3-subtitle-card>
                  <FormattedMessage
                    id="dudaPreTra"
                    defaultMessage="¿El asegurado es quien realiza el trámite?"
                  />
                </h3-subtitle-card>
              </Grid>
              <Grid item xs={"auto"} style={{ marginTop: "20px" }}>
                <Check2
                  option1={<FormattedMessage id="globNo" defaultMessage="No" />}
                  option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
                  checked={aseguradoSi}
                  disabled={
                    tipoTramite === TRAM_FALLECIMIENTO.id ? true : false
                  }
                  onChange={toogleSwitch}
                />
              </Grid>
            </Grid>
            {/*formulario que aparece cuando se selecciona si en asegurado es quien realiza el tramite */}
            <Grid xs={12}>
              {aseguradoSi ? (
                <FormTramiteAsegurado dataContacto={dataAcuse.dataAsegurado} />
              ) : (
                <SelectorPersona
                  name="selectPersona"
                  id="selectPersona"
                  rolPersona={(e) => setIdRolPer(e)}
                />
              )}
              <br></br>
            </Grid>
          </Grid>

          {/*radio de documentacion */}
          <Grid item xs={4} style={{ marginTop: "20px", marginBottom: "20px" }}>
            <h3-header-card style={{ marginBottom: "20px" }}>
              <FormattedMessage
                id="dudaPreDoc"
                defaultMessage="¿Se cuenta con documentación?"
              />
            </h3-header-card>
            <Check2
              option1={<FormattedMessage id="glob.no" defaultMessage="No" />}
              option2={<FormattedMessage id="glob.si" defaultMessage="Si" />}
              onChange={toogleSwitch2}
            />
          </Grid>
          {/*tabla */}
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            {documentacionSi ? (
              <TablaDocumentacion
                limpiarTabla={limpiarTabla}
                limpiarTablaCompleto={limpiarTablaCompleto}
                arrayDocs={docsTramite}
                documentos={(e) => setDocumentos(e)}
                documentosExpTram={(e) => setDocumentosExpTram(e)}
              />
            ) : null}
          </Grid>
          <hr></hr>
          {/*botones */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={"auto"}>
              <DialogButtons
                mainButton={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
                title={
                  <FormattedMessage
                    id="recepCanRe"
                    defaultMessage="Cancelar recepción"
                  />
                }
                description={
                  <FormattedMessage
                    id="msgRecepGu"
                    defaultMessage="Si cancela la recepción se perderán los datos guardados, ¿Desea continuar?"
                  />
                }
                firstButton={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
                secondButton={
                  <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
                }
                onClickAccept={() => (window.location.href = "/recepcion")}
              />
            </Grid>

            <Grid item xs={"auto"}>
              <ButtonVino
                label={
                  !btnGenerarFolio ? "Generar folio" : "Generando folio..."
                }
                type="submit"
                estatus={btnGenerarFolio}
              />
            </Grid>
            {!mostrarDialogo ? null : (
              <DialogGenFolio
                open={mostrarDialogo}
                dataAcuse={dataAcuse}
                AcuseChecked={(e) => setAcuseChecked(e)}
                NoAleatorio={NoAleatorio}
                user={user}
                nombreAse={`${dataAse.nombre} ${dataAse.apePat} ${dataAse.apeMat}`}
                aceptar={(e) => {
                  redireccionar(dataAcuse);
                }}
              />
            )}
          </Grid>
        </form>
      </div>
    </div>
  );
}
