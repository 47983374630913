import React from "react";
import "../../../../css/estilo.css";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Select1 } from "../../../Tools/Selectss/Select1";
import { getDataToken } from "../../../../api/dataToken";
import { LimitarDigitos } from "../Functions/Validaciones";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";
import { Email } from "@mui/icons-material";

export default function FormTramiteAsegurado(props) {
  let dataContacto = props.dataContacto;
  console.log("contacto:", dataContacto);
  useEffect(() => {
    setCodigoPostal(dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal);
  }, []);

  const [contacto, setContacto] = useState({
    idPersona: dataContacto.PER_idpersona?.PER_idpersona,
    txtInt: dataContacto.PER_idpersona?.PER_numerointerior,
    txtExt: dataContacto?.PER_idpersona?.PER_numeroexterior,
    txtCalle: dataContacto?.PER_idpersona?.PER_calle,
    txtCP: dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal,
    txtTelCel: dataContacto?.PER_idpersona?.PER_celular,
    txtTelFijo: dataContacto?.PER_idpersona?.PER_telefono,
    txtCorreo: dataContacto?.PER_idpersona?.PER_correo,
    txtEstado: dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_estado,
    txtMunicipio: dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_municipio,
    txtColonia: dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_asenta,
    txtSep_id: dataContacto?.PER_idpersona?.SEP_idsepomex?.id,
  });

  /*funciones CP sepomex */
  const [SEPT_asenta, setSEPT_asenta] = useState(
    dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_asenta
  );
  const [SEPT_municipio, setSEPT_municipio] = useState(
    dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_municipio
  );
  const [SEPT_estado, setSEPT_estado] = useState(
    dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_estado
  );
  const [codigopostal, setCodigoPostal] = useState(
    dataContacto?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal
  );

  const [colonias, setColonias] = useState([]);
  const [dataSelectColonias, setDataSelectColonias] = useState([]);

  const [helperCP, setHelperCP] = useState("");
  //Traer los datos de CP

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonias(result);
      if (result <= 0) {
        setHelperCP("No hay Información.");
        setDataSelectColonias([]);
        setSEPT_estado("");
        setSEPT_municipio("");
        setSEPT_asenta("");
        setContacto((prevState) => ({
          ...prevState,
          txtSep_id: "",
        }));
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //CP

  const handleSubmitone = () => {
    if (codigopostal === "") {
      document.getElementById("txtCP").focus();
    } else {
      getCodP(codigopostal);
      setHelperCP("");
    }
  };

  const getInfoasentamiento = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEPT_municipio(element.SEP_municipio);
        setSEPT_estado(element.SEP_estado);
        setSEPT_asenta(element.SEP_asenta);
        setContacto((prevState) => ({
          ...prevState,
          txtSep_id: element.id,
          txtCP: element.SEP_codpostal,
          txtColonia: element.SEP_asenta,
          txtEstado: element.SEP_estado,
          txtMunicipio: element.SEP_municipio,
        }));
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    if (colonias?.length > 0) {
      let dataColonias = colonias;
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias);
    }
  }, [colonias]);

  /** ACTUALIZAR DATOS DEL CONTACTO, ENVIAR AL COMPONENTE PADRE */

  useEffect(() => {
    props.newDataContacto(contacto);
  }, [contacto]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      style={{ marginTop: "20px" }}
    >
      {/*campos a llenar correo y telefonos */}
      <Grid item xs={3}>
        <TXTField
          label={
            <FormattedMessage
              id="comuCorreo"
              defaultMessage="Correo eléctronico"
              type="email"
            />
          }
          id="txtCorreo"
          name="txtCorreo"
          type={Email}
          defaultValue={contacto.txtCorreo}
          onChange={(e) => {
            setContacto((prevState) => ({
              ...prevState,
              txtCorreo: e.target.value,
            }));
          }}
          // required
        />
      </Grid>
      <Grid item xs={3}>
        <TXTField
          label={
            <FormattedMessage id="txtTelFijo" defaultMessage="Teléfono fijo" />
          }
          id="txtTelFijo"
          name="txtTelFijo"
          onInput={(e) => LimitarDigitos(e.target, 10)}
          defaultValue={contacto.txtTelFijo}
          onChange={(e) => {
            setContacto((prevState) => ({
              ...prevState,
              txtTelFijo: e.target.value,
            }));
          }}
          // required
        />
      </Grid>
      <Grid item xs={3}>
        <TXTField
          label={
            <FormattedMessage
              id="txtTelCel"
              defaultMessage="Teléfono celular"
            />
          }
          id="txtTelCel"
          name="txtTelCel"
          onInput={(e) => LimitarDigitos(e.target, 10)}
          defaultValue={contacto.txtTelCel}
          onChange={(e) => {
            setContacto((prevState) => ({
              ...prevState,
              txtTelCel: e.target.value,
            }));
          }}
          // required
        />
      </Grid>
      {/* en este grid se escribira el codigo postal y posteriormente lo buscaremos en el boton buscar*/}
      <Grid item xs={3}>
        <TXTField
          helperText={helperCP}
          label={
            <FormattedMessage id="globCodPos" defaultMessage="Código postal" />
          }
          name="txtCP"
          id="txtCP"
          onChange={(event) => {
            setCodigoPostal(event.target.value);
          }}
          idIcon="iconCP"
          nameIcon="iconCP"
          onInput={(e) => LimitarDigitos(e.target, 6)}
          showSearchIcon={true}
          iconOnClick={handleSubmitone}
          defaultValue={contacto.txtCP}
        />
      </Grid>

      <Grid item xs={3}>
        <Select1
          label={
            <FormattedMessage
              id="globSelecColoni"
              defaultMessage="Seleccionar colonia"
            />
          }
          array={dataSelectColonias}
          onChange={getInfoasentamiento}
          id="SEP_idsepomex"
          name="SEP_idsepomex"
        />
      </Grid>
      {/*campos donde aparece la localidad cuando se busca por codigo postal*/}

      <Grid item xs={3}>
        <TXTField
          label={<FormattedMessage id="globEstado" defaultMessage="Estado" />}
          readOnly
          name="txtEstado"
          value={SEPT_estado}
          InputLabelProps={{
            shrink: true, // For Material-UI TextField to keep the label always above
          }}
          // required
        />
      </Grid>
      <Grid item xs={3}>
        <TXTField
          label={
            <FormattedMessage
              id="globMunicipioAlcadia"
              defaultMessage="Municipio o Alcaldia"
            />
          }
          value={SEPT_municipio}
          readOnly
          name="txtMunicipio"
          InputLabelProps={{
            shrink: true, // For Material-UI TextField to keep the label always above
          }}
          // required
        />
      </Grid>
      <Grid item xs={3}>
        <TXTField
          label={<FormattedMessage id="globColoni" defaultMessage="Colonia" />}
          name="txtColonia"
          readOnly
          value={SEPT_asenta}
          InputLabelProps={{
            shrink: true, // For Material-UI TextField to keep the label always above
          }}
          // required
        />
      </Grid>
      {/*campos domicilio */}
      <Grid item xs={3}>
        <TXTField
          id="txtCalle"
          label={<FormattedMessage id="globCalle" defaultMessage="Calle" />}
          name="txtCalle"
          onChange={(e) => {
            setContacto((prevState) => ({
              ...prevState,
              txtCalle: e.target.value,
            }));
          }}
          defaultValue={contacto.txtCalle}

          // required
        />
      </Grid>
      <Grid item xs={3}>
        <TXTField
          label={<FormattedMessage id="txtExt" defaultMessage="No. exterior" />}
          id="txtExt"
          name="txtExt"
          onInput={(e) => LimitarDigitos(e.target, 4)}
          onChange={(e) => {
            setContacto((prevState) => ({
              ...prevState,
              txtExt: e.target.value,
            }));
          }}
          defaultValue={contacto.txtExt}

          // required
        />
      </Grid>
      <Grid item xs={3}>
        <TXTField
          label={<FormattedMessage id="txtInt" defaultMessage="No. interior" />}
          id="txtInt"
          name="txtInt"
          onInput={(e) => LimitarDigitos(e.target, 4)}
          // required
          onChange={(e) => {
            setContacto((prevState) => ({
              ...prevState,
              txtInt: e.target.value,
            }));
          }}
          defaultValue={contacto.txtInt}
        />
      </Grid>
      {/**espacio adicional para regimen fiscal  */}
      {/* <Grid xs={3}>
              <TextField
                label={
                  <FormattedMessage
                    id="receRegFis"
                    defaultMessage="Régimen fiscal"
                  />
                }
                size="small"
                sx={{ m: 1, minWidth: 50, background: "white" }}
                style={{ position: "sticky" }}
                onchange={handleForm("txtRegimen")}
                name="txtRegimen"
              />
            </Grid> */}
    </Grid>
  );
}
