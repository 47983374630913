/* Brandon Hernandez Rocha 12/2022 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { Grid } from "@mui/material";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";
import {
  postDataToken,
  getDataToken,
  putDataToken,
} from "../../../../api/dataToken";
import { IDMODDICTAMEN } from "../../../../utils/constanstoken";
import getDate from "../../../Registro/Function/obtenerFecha";
import getHours from "../../../Registro/Function/obtenerHora";
import moment from "moment";
import { formatofecha } from "../../../../api/getUmbrales";
import { LimitarTexto } from "../../../Recepcion/Elements/Functions/Validaciones";
//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogEnviarMedico(props) {
  const polizas = props.polizas;
  const idFolio = polizas[0]?.RECT_idfolio?.RECT_idfolio;
  console.log(polizas);
  console.log(idFolio);

  const [values, setValues] = React.useState({
    MSG_mensaje: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  console.log(values.MSG_mensaje);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idTrabajador, setidTrabajador] = React.useState("");
  const [charola, setcharola] = React.useState([]);
  const [guardadoMSG, setguardadoMSG] = useState([]);
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setidTrabajador(result.id);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
      var charolamedico = await getDataToken(
        `charolamedico/get_folio/${idFolio}/`
      );
      setcharola(charolamedico);
      var resultmsg = await getDataToken(
        `mensajes/historical_filter/?folio=${idFolio}&poliza=${polizas[0]?.POL_idpoliza?.POL_idpoliza}&modulo=${IDMODDICTAMEN}`
      );
      setguardadoMSG(resultmsg);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  console.log(charola);
  console.log(guardadoMSG);
  //PARENT DIALOG
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //CHILDREN DIALOG
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
    if (guardadoMSG?.length > 0) {
      for (const datos of polizas) {
        const guardarmsg = {
          RECT_idfolio: idFolio,
          POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
          IDTRABAJADOR: idTrabajador,
          WFM_idwfmodulo: IDMODDICTAMEN,
          MSG_mensaje: values.MSG_mensaje,
          MSG_actualizofecha: fechaActSIN,
          MSG_actualizousuario: nombreAnalista,
        };
        console.log(guardarmsg);
        putDataToken(
          `mensajes/get_id/${guardadoMSG[0]?.MSG_idmensaje}/`,
          guardarmsg
        ).then((data) => {
          console.log(`Registro ${guardadoMSG[0].MSG_idmensaje} actualizado`);
          console.log(data);
        });
      }
    } else {
      for (const datos of polizas) {
        const guardarmsg = {
          RECT_idfolio: idFolio,
          POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
          IDTRABAJADOR: idTrabajador,
          WFM_idwfmodulo: IDMODDICTAMEN,
          MSG_mensaje: values.MSG_mensaje,
          MSG_actualizofecha: fechaActSIN,
          MSG_actualizousuario: nombreAnalista,
        };
        postDataToken(`mensajes/get_listado/`, guardarmsg).then((data) => {
          console.log(`Registro exitoso`, data);
        });
      }
    }
    charola.forEach((datoscharola) => {
      let actualizarcharola = {
        RECT_idfolio: datoscharola?.RECT_idfolio?.RECT_idfolio,
        PER_idpersona: datoscharola?.PER_idpersona?.PER_idpersona,
        SIN_idsiniestro: datoscharola?.SIN_idsiniestro?.SIN_idsiniestro,
        CHMED_entregado: false,
        CHMED_actualizofecha: fechaActSIN,
        CHMED_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `charolamedico/get_id/${datoscharola?.CHMED_idcharolamed}/`,
        actualizarcharola
      ).then((data) => {
        console.log(`Registro ${datoscharola?.CHMED_idcharolamed} actualizado`);
      });
    });
  };
  const handleClose2 = () => {
    setOpen2(false); // Close the children dialog
    handleClose(); // Close the parent dialog
  };

  /* AQUI SE TIENE QUE VALIDAR LA RESPUESTA JSON, SI FUE EXITOSO O NO EL ENVIO A MÉDICO */
  const [dialogTittle, setDialogTittle] = React.useState("");
  const [dialogMessage, setDialogMessage] = React.useState("");

  //AQUI DEBE HACER LA PETICIÓN A LA API PARA DESPUES MOSTRAR LA RESPUESTA EN EL DIALOGO HIJO
  React.useEffect(() => {
    setDialogTittle("Confirmación");
    setDialogMessage(
      <div>
        <h2-dialog-description>
          ¡Se realizó el envío de forma exitosa!
        </h2-dialog-description>
      </div>
    );
  }, []);

  return (
    <div>
      {/* PARENT DIALOG */}
      <div>
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
        <ButtonVino
          onClick={handleClickOpen}
          label={props.mainButton}
          estatus={props.disabledbtn}
        />
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby="dialog"
          open={open}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITILO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose}
            style={{ color: "#761f2a" }}
          >
            <h1-dialog-title>{props.title}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
            <h2-dialog-description>{props.description}</h2-dialog-description>
            <h2-dialog-description>
              <label>
                {
                  <FormattedMessage
                    id="glob.comentarios"
                    defaultMessage="Comentarios"
                  />
                }
              </label>
            </h2-dialog-description>
            <TXTField
              type="text"
              size="small"
              multiline={true}
              rows={3}
              onChange={handleChange("MSG_mensaje")}
              value={values?.MSG_mensaje}
              onInput={(e) => LimitarTexto(e.target, 255)}
            />
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={"auto"}>
                <ButtonVino
                  onClick={handleClose}
                  label={"Cancelar"}
                  estatus={props.disabledBtn}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <ButtonVino
                  onClick={handleClickOpen2}
                  label={"Enviar"}
                  estatus={values?.MSG_mensaje ? false : true}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
      {/* CHILDREN DIALOG */}
      <div>
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          onClose={handleClose2}
          aria-labelledby="dialog"
          open={open2}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITULO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose2}
            style={{ color: "#761f2a" }}
          >
            <h1-dialog-title>{dialogTittle}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
              se necesita colocar entre un typogreaphy las veces que des salto de linea 
              o con un br*/}
            <h2-dialog-description>{dialogMessage}</h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid container justifyContent="center">
              <ButtonVino onClick={handleClose2} label={"Aceptar"} />
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
}
